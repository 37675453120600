import img_google from 'public/img/ggl.svg';
import img_google_photos from 'public/img/ggp.svg';
import img_google_news from 'public/img/ggn.svg';
import img_facebook from 'public/img/fb.svg';
import img_crowdtangle from 'public/img/ct.jpg';
import img_twitter from 'public/img/tw.svg';
import img_instagram from 'public/img/ig.svg';
import img_pinterest from 'public/img/pi.svg';

import React, { useEffect } from 'react';
import baseApiClient from 'src/apollo/client';
import useAsyncHandler from 'src/hooks/useAsyncHandler';

import './style.css';

type ExternalSearchProps = {
  selected?: string;
  disabled?: boolean;
};

const TYPES = {
  facebook: {
    img: img_facebook,
    title: 'Facebook search for given keywords',
    alt: 'Facebook search icon'
  },
  instagram: {
    img: img_instagram,
    title: 'Instagramm search for given keywords',
    alt: 'Instagram search icon'
  },
  pinterest: {
    img: img_pinterest,
    title: 'Pinterest search for given keywords',
    alt: 'Pinterest search icon'
  },
  google: {
    img: img_google,
    title: 'Google search for given keywords',
    alt: 'Google search icon'
  },
  googlenews: {
    img: img_google_news,
    title: 'Google news for given keywords',
    alt: 'Google news search icon'
  },
  googleimages: {
    img: img_google_photos,
    title: 'Google images for given keywords',
    alt: 'Google images search icon'
  },
  crowdtangle: {
    img: img_crowdtangle,
    title: 'Growdtangle search for given keywords',
    alt: 'Crowdtangle search icon'
  },
  twitter: {
    img: img_twitter,
    title: 'Twitter search for given keywords',
    alt: 'Twitter search icon'
  }
};

type Link = {
  type:
    | 'facebook'
    | 'instagram'
    | 'pinterest'
    | 'google'
    | 'googlenews'
    | 'googleimages'
    | 'crowdtangle'
    | 'twitter';
  url: string;
};

const linksResolver = (params) => baseApiClient('search_links', params);

export default function ExternalSearch(
  props: ExternalSearchProps
): JSX.Element | null {
  const { selected, disabled } = props;
  const [getLinks, { data }] = useAsyncHandler(linksResolver);

  useEffect(() => {
    if (selected && !disabled) {
      getLinks({ keywords: selected });
    }
  }, [selected, disabled, getLinks]);

  return (
    <nav className='ExternalSearch'>
      {data
        ? data.map(({ type, url }) => (
            <a
              href={url}
              key={url}
              target='_blank'
              rel='noreferrer'
              title={TYPES[type].title}
            >
              <img src={TYPES[type].img} alt={TYPES[type].alt} />
            </a>
          ))
        : 'Loading...'}
    </nav>
  );
}
