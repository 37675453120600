import React, { useCallback, useEffect, useState } from 'react';
import { isPlainObject, runIfExists, set } from '../../util';
import FormContext from './context';
import type { FormProviderProps } from './types';

export default function FormProvider(props: FormProviderProps) {
  const {
    formId,
    values,
    onChange,
    children,
    uniqueId,
    onSubmit,
    onReset,
    disabled,
    renderForm,
    formClassName
  } = props;

  const [formValues, setFormValues] = useState<object>(values || {});

  useEffect(() => {
    setFormValues(values || {});
    // Intentionally reset form values only if item ID changes,
    // this is solution for a case that somethimes selectors could return
    // new data object but with the same context, and it will fail default comarison check
    // ================================================================
  }, [uniqueId]);

  const changeHandler = useCallback(
    (change) =>
      setFormValues((oldValues: object): object => {
        const customResult = runIfExists(onChange, { ...oldValues }, change);
        if (isPlainObject(customResult)) {
          return customResult;
        }
        return set({ ...oldValues }, change.name, change.value);
      }),
    [onChange]
  );

  const submitHandler = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onSubmit({ variables: formValues });
    },
    [onSubmit, formValues]
  );

  const contextValue = {
    disabled: !!disabled,
    values: formValues,
    onSubmit: submitHandler,
    onReset: onReset,
    setValue: changeHandler
  };

  return (
    <FormContext.Provider value={contextValue}>
      {renderForm ? (
        <form
          id={formId}
          className={formClassName}
          onSubmit={submitHandler}
          onReset={onReset}
        >
          {children}
        </form>
      ) : (
        children
      )}
    </FormContext.Provider>
  );
}
