import { makeExecutableSchema } from '@graphql-tools/schema';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import type { ErrorResponse } from '@apollo/client/link/error';
import { SchemaLink } from '@apollo/client/link/schema';
import { onError } from '@apollo/client/link/error';

import { typeDefs } from '@type-defs';

import { resolvers } from './resolvers';

export function createApolloClient() {
  const schema = makeExecutableSchema({
    typeDefs,
    resolvers
  });

  const cache = new InMemoryCache({
    typePolicies: {
      User: {
        keyFields: []
      },
      Theme: {
        keyFields: ['theme_id']
      },
      Article: {
        keyFields: ['hash']
      },
      OpinionCluster: {
        // keyFields: ['identifier']
      }
    }
  });

  const schemaLink = new SchemaLink({ schema });

  const errorLink = onError(({ graphQLErrors }: ErrorResponse) => {
    if (
      graphQLErrors &&
      graphQLErrors.some((err) => err.message === 'Not logged in')
    ) {
      cache.reset();
    }
  });

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, schemaLink]),
    cache,
    connectToDevTools: __NODE_ENV__ !== 'production'
  });

  return client;
}
