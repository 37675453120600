import React, { useEffect, useState } from 'react';

type DelayedRenderProps = {
  trigger: boolean;
  children: React.ReactNode;
  component: React.ReactNode;
  delay?: number;
};

export default function DelayedRender(props: DelayedRenderProps): JSX.Element {
  const { trigger, children, component, delay = 300 } = props;

  const [render, setRender] = useState<boolean>(false);

  useEffect(() => {
    let timer = null;
    if (trigger) {
      timer = setTimeout(() => setRender(true), delay);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
        setRender(false);
      }
    };
  }, [trigger, delay]);

  return render ? component : children;
}
