import type { Trend, QueryGetTrendsArgs } from '@schema-types';
import { trendsClient, trendsv2Client } from '../client';

export const trendResolver = {
  Query: {
    GetTrends: async (
      _,
      { q, segment, since, since_hours }: QueryGetTrendsArgs
    ): Promise<Trend[]> => {
      const params: QueryGetTrendsArgs = { segment };
      if (q) {
        params.q = q;
      }
      if (since) {
        params.since = since;
      }
      if (since_hours) {
        params.since_hours = since_hours;
      }
      const data = await trendsv2Client(params);
      if (!data[segment]) {
        return [];
      }
      return data[segment].map((item) => {
        return {
          ...item,
          label: Object.keys(item.theme_stats).join(',')
        };
      });
    },
    GetTrend: async (_, params): Promise<Trend> => {
      const data: Trend = await trendsClient(params);
      return {
        ...data,
        label: Object.keys(data.theme_stats).join(',')
      };
    }
  }
};
