import React from 'react';
import { Link } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { LogIn } from 'src/apollo/mutations/user.graphql';
import { GetUser } from 'src/apollo/queries/user.graphql';

import Text from 'src/Text';
import TextInput from 'src/Components/TextInput';
import FormProvider, { Field } from 'src/Components/Form';
import Button from 'src/Components/Button';

import './style.css';

type LoginFormProps = {
  registerPath?: string;
};

export default function LoginForm(props: LoginFormProps) {
  const { registerPath } = props;
  const [onSubmit, { loading, error }] = useMutation(LogIn, {
    refetchQueries: [GetUser],
    awaitRefetchQueries: true
  });
  return (
    <section className='LoginForm'>
      <h1 className='LoginForm-title'>
        <Text id='login' fallback='Login' />
      </h1>
      <FormProvider
        formId='authentication'
        renderForm
        onSubmit={onSubmit}
        formClassName='LoginForm card vertical-rhythm'
      >
        {!loading && error ? (
          <div className='error-message'>{error.toString()}</div>
        ) : null}
        <Field
          id='email'
          name='email'
          Component={TextInput}
          type='email'
          disabled={loading}
          label={<Text id='email' fallback='Email' />}
        />
        <Field
          id='password'
          name='password'
          Component={TextInput}
          type='password'
          disabled={loading}
          label={<Text id='password' fallback='Password' />}
        />
        <div className='LoginForm-controls'>
          <a href='' className='noop'>
            <Text id='forgotpass' fallback='Forgot password?' />
          </a>
          <Button loading={loading} type='submit' className='LoginForm-submit'>
            <Text id='login' fallback='Login' />
            <b>&nbsp;&gt;</b>
          </Button>
        </div>
      </FormProvider>
      <div className='LoginForm-adendum'>
        {registerPath ? (
          <Link to={registerPath}>
            <Text
              id='noaccount'
              fallback='No login yet create an account here.'
            />
          </Link>
        ) : null}
      </div>
    </section>
  );
}
