import { createContext } from 'react';
import type { FormContextType } from './types';

export default createContext<FormContextType>({
  values: {},
  disabled: false,
  onSubmit: console.error,
  onReset: console.error,
  setValue: () => ({})
});
