import React, { useCallback } from 'react';
import cn from 'classnames';

import Text from 'src/Text';
import { runIfExists } from 'src/util';

import './style.css';

type NewsArticleProps = {
  id: string;
  title: string;
  url: string;
  domain?: string;
  likes: number;
  rate_likes: number;
  description?: string | null;
  image?: string | null;
  className?: string;
  selected?: boolean;
  onClick?: (id: string) => void;
};

export default function NewsArticle(props: NewsArticleProps): JSX.Element {
  const {
    id,
    title,
    url,
    domain,
    likes,
    rate_likes,
    description,
    onClick,
    selected,
    image,
    className
  } = props;

  const clickHandler = useCallback(
    () => runIfExists(onClick, id),
    [id, onClick]
  );

  return (
    <article
      className={cn('NewsArticle', className, { 'state-selected': selected })}
      onClick={!selected ? clickHandler : null}
    >
      <div className='NewsArticle-content'>
        <h1 className='NewsArticle-title'>{title}</h1>
        <h2 className='NewsArticle-subtitle'>
          <a href={url}>{domain}</a>
          {' | '}
          {rate_likes}{' '}
          <Text id='engagementperhour' fallback='engagements/hour' /> ({likes}{' '}
          <Text id='total' fallback='Total' />)
        </h2>
        {description ? (
          <p className='NewsArticle-description'>{description}</p>
        ) : null}
      </div>
      <img className='NewsArticle-media' src={image} alt={title} aria-hidden />
    </article>
  );
}
