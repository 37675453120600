import React, { useCallback } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { GetUser } from 'src/apollo/queries/user.graphql';
import { EditUser } from 'src/apollo/mutations/user.graphql';
import { get } from 'src/util';

import Text from 'src/Text';
import useTranslation from 'src/Text/useTranslation';

import Dialog from 'src/Components/Dialog';
import TextInput from 'src/Components/TextInput';
import Select from 'src/Components/Select';
import FormProvider, { Field } from 'src/Components/Form';
import Button from 'src/Components/Button';

import AlertSettings from './AlertSettings';

import './style.css';

type UserSettingsProps = {
  id: string;
  title: React.ReactNode;
  open: boolean;
  onClose: (e: React.MouseEvent) => void;
};

export default function UserSettings(props: UserSettingsProps): JSX.Element {
  const { id, title, open, onClose } = props;
  const { data } = useQuery(GetUser);
  const [onSubmit, { loading, error }] = useMutation(EditUser);

  const regionalText = useTranslation('regional', 'Regional');
  const nationalText = useTranslation('national', 'National');

  const wrapSubmit = useCallback(
    ({ variables }) =>
      onSubmit({
        variables: {
          ...variables,
          since_hours: parseInt(variables.since_hours) || 24
        }
      }),
    [onSubmit]
  );

  return (
    <Dialog
      id={id}
      title={title}
      open={open}
      width='480px'
      height='72vh'
      onClose={onClose}
      className='UserSettings'
      wrapContent={false}
    >
      <FormProvider
        formId='user-editing'
        renderForm
        values={get(data, 'User', {})}
        onSubmit={wrapSubmit}
        formClassName='UserSettings-form vertical-rhythm'
      >
        {!loading && error ? (
          <div className='error-message'>{error.toString()}</div>
        ) : null}
        <Field
          id='firstname'
          name='firstname'
          Component={TextInput}
          type='text'
          disabled={loading}
          label={<Text id='firstname' fallback='Firstname' />}
        />
        <Field
          id='lastname'
          name='lastname'
          Component={TextInput}
          type='text'
          disabled={loading}
          label={<Text id='lastname' fallback='Lastname' />}
        />
        <Field
          id='email'
          name='email'
          Component={TextInput}
          type='email'
          disabled={loading}
          label={<Text id='email' fallback='Email' />}
        />
        <Field
          id='language'
          name='language'
          Component={Select}
          disabled={loading}
          label={<Text id='language' fallback='Language' />}
        >
          <option value='English'>English</option>
          <option value='Dutch'>Dutch</option>
        </Field>
        <Field
          id='organization'
          name='organization'
          Component={TextInput}
          type='text'
          disabled={loading}
          label={<Text id='organization' fallback='Organization' />}
        />
        <Field
          id='region'
          name='region'
          Component={Select}
          disabled={loading}
          label={<Text id='region' fallback='Region' />}
        >
          <option value='Nationaal'>{nationalText}</option>
          <option value='Regionaal'>{regionalText}</option>
        </Field>
        <Field
          id='since_hours'
          name='since_hours'
          Component={TextInput}
          type='number'
          min='0'
          step='1'
          disabled={loading}
          label={<Text id='sincehours' fallback='Since hours' />}
        />
        <Field
          id='slack_hook'
          name='slack_hook'
          Component={TextInput}
          type='text'
          disabled={loading}
          label={<Text id='slackhook' fallback='Slack hook' />}
        />
        <AlertSettings className='UserSettings-alerts' />
      </FormProvider>
      <footer className='UserSettings-footer'>
        <Button
          loading={loading}
          type='submit'
          form='user-editing'
          className='UserSettings-submit'
        >
          <Text id='submit' fallback='Submit' />
        </Button>
      </footer>
    </Dialog>
  );
}
