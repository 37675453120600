import React, { useCallback } from 'react';
import cn from 'classnames';
import type { FieldHookProps } from '../Form/types';

import './style.css';

type SelectProps = {
  id: string;
  className?: string;
  disabled?: boolean;
  label?: React.ReactNode;
  children: React.ReactNodeArray;
};

export default function Select(props: FieldHookProps & SelectProps) {
  const { id, name, label, className, value, children, disabled, onChange } =
    props;

  const changeHandler = useCallback(
    ({ target }) => onChange({ name, value: target.value }),
    [name, onChange]
  );

  return (
    <div className={cn('Select', className)}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <select
        id={id}
        name={name}
        disabled={disabled}
        value={value}
        onChange={changeHandler}
      >
        {children}
      </select>
    </div>
  );
}
