import { useContext } from 'react';
import { get } from '../../util';
import Context from './context';
import type { FormHookProps, FieldHookProps } from './types';

export const useForm = (): FormHookProps => {
  const { onSubmit, onReset } = useContext(Context);
  return {
    onSubmit,
    onReset
  };
};

export const useField = (name: string, fallback = ''): FieldHookProps => {
  const { values, setValue, disabled } = useContext(Context);
  const formHookProps = {
    name,
    disabled,
    value: get(values, name, fallback),
    onChange: setValue
  };

  return formHookProps;
};
