import { useCallback, useMemo, useState } from 'react';
import { debounce } from 'src/util';

type AsyncHandlerState = {
  loading: boolean;
  error: string | null;
  data: unknown;
  called: number;
};

type AsyncHandlerResolver = (
  arg?: Record<string, unknown>
) => Promise<Record<string, unknown> | Record<string, unknown>[] | null>;

const INIT_STATE = {
  called: 0,
  loading: true,
  data: null,
  error: null
};

const STATE_DONE = {
  called: 0,
  loading: false,
  error: null
};

export default function useAsyncHandler(
  resolver: AsyncHandlerResolver
): [AsyncHandlerResolver, AsyncHandlerState] {
  const [state, setState] = useState<AsyncHandlerState>(INIT_STATE);

  const runHanlder = useCallback(
    async (
      arg: Record<string, unknown>
    ): Promise<Record<string, unknown> | Record<string, unknown>[] | null> => {
      try {
        setState(({ called }) => ({ ...INIT_STATE, called: called + 1 }));
        const result = await resolver(arg);
        setState(({ called }) => ({ ...STATE_DONE, called, data: result }));
        return result;
      } catch (err: any) {
        setState(({ called }) => ({
          called,
          loading: false,
          error: err.toString(),
          data: null
        }));
        return null;
      }
    },
    [resolver]
  );

  /* const debouncedHandler = useMemo(
    () => debounce(runHanlder, 1300),
    [runHanlder]
  ); */
  return [runHanlder, { ...state }];
}
