import React, { useCallback, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { GetUserAlerts } from 'src/apollo/queries/useralerts.graphql';
import {
  ActivateChannel,
  DeactivateChannel,
  ChangeMethod
} from 'src/apollo/mutations/useralerts.graphql';

import LoadingSpinner from 'src/Components/LoadingSpinner';
import DelayedRender from 'src/Components/DelayedRender';

import Text from 'src/Text';

type AlertSettingsProps = {
  children?: React.ReactNode;
  className: string;
};

type SwitcherState = {
  daily: 0 | 1;
  email_active: 0 | 1;
  realtime: 0 | 1;
  slack_active: 0 | 1;
};

const BLANK_STATE: SwitcherState = {
  daily: 0,
  email_active: 0,
  realtime: 0,
  slack_active: 0
};

export default function AlertSettings(props: AlertSettingsProps): JSX.Element {
  const { children, className } = props;

  const [{ daily, email_active, realtime, slack_active }, setSwitchers] =
    useState<SwitcherState>(BLANK_STATE);

  const [activateChannel, activateChannelMutation] = useMutation(
    ActivateChannel,
    {
      refetchQueries: [GetUserAlerts]
    }
  );
  const [deactivateChannel, deactivateChannelMutation] = useMutation(
    DeactivateChannel,
    {
      refetchQueries: [GetUserAlerts]
    }
  );
  const [changeMethod, changeMethodMutation] = useMutation(ChangeMethod, {
    refetchQueries: [GetUserAlerts]
  });

  const { loading, error } = useQuery(GetUserAlerts, {
    onCompleted: (data) => {
      if (data) {
        setSwitchers(data.UserAlerts);
      }
    }
  });

  const toggleChannel = useCallback(
    async (e) => {
      const channel = e.target.value;
      setSwitchers((old) => ({ ...old, [e.target.name]: e.target.checked }));
      if (e.target.checked) {
        await activateChannel({ variables: { channel } });
      } else {
        await deactivateChannel({ variables: { channel } });
      }
    },
    [activateChannel, deactivateChannel]
  );

  const switchAlertMethod = useCallback(
    (e) => {
      const method = e.target.value;
      if (!e.target.checked) return;
      setSwitchers((old) => ({
        ...old,
        realtime: 0,
        daily: 0,
        [e.target.name]: true
      }));
      changeMethod({ variables: { method } });
    },
    [changeMethod]
  );

  const mutationError =
    error ||
    activateChannelMutation.error ||
    deactivateChannelMutation.error ||
    changeMethodMutation.error;

  return (
    <fieldset className={className}>
      {mutationError ? (
        <div className='error-message'>{mutationError.toString()}</div>
      ) : null}
      <DelayedRender trigger={!!loading} component={<LoadingSpinner />}>
        {children}
        <label className={`${className}-row`}>
          <Text id='sendviaemail' fallback='Send via email' />
          <input
            type='checkbox'
            data-switcher
            name='email_active'
            checked={!!email_active}
            value='email'
            id='email_active'
            onChange={toggleChannel}
          />
        </label>
        <label className={`${className}-row`}>
          <Text id='sendviaslack' fallback='Send via slack' />
          <input
            type='checkbox'
            data-switcher
            name='slack_active'
            checked={!!slack_active}
            value='slack'
            id='slack_active'
            onChange={toggleChannel}
          />
        </label>
        <label className={`${className}-row`}>
          <Text id='senddaily' fallback='Send alerts daily' />
          <input
            type='checkbox'
            data-switcher
            name='daily'
            value='daily'
            onChange={switchAlertMethod}
            checked={!!daily}
            id='daily'
          />
        </label>
        <label className={`${className}-row`}>
          <Text id='sendrealtime' fallback='Send alerts realtime' />
          <input
            type='checkbox'
            data-switcher
            name='realtime'
            value='realtime'
            onChange={switchAlertMethod}
            checked={!!realtime}
            id='realtime'
          />
        </label>
      </DelayedRender>
    </fieldset>
  );
}
