import React from 'react';
import { Link } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { CreateUser } from 'src/apollo/mutations/user.graphql';
import { GetUser } from 'src/apollo/queries/user.graphql';

import { get } from 'src/util';

import Text from 'src/Text';
import TextInput from 'src/Components/TextInput';
import FormProvider, { Field } from 'src/Components/Form';
import Button from 'src/Components/Button';

import './style.css';

type RegisterFromProps = {
  loginPath?: string;
};

export default function RegisterForm(props: RegisterFromProps) {
  const { loginPath } = props;
  const [onSubmit, { data, called, loading, error }] = useMutation(CreateUser, {
    refetchQueries: [GetUser],
    awaitRefetchQueries: true
  });
  return (
    <section className='RegisterForm'>
      <h1 className='RegisterForm-title'>
        <Text id='register' fallback='Registration' />
      </h1>
      {called && !loading && get(data, 'CreateUser.success', false) ? (
        <div className='RegisterForm card vertical-rhythm'>
          <h1>
            <Text id='accountcreated' fallback='New account created' />
          </h1>
          <p>
            <Text
              id='confirmationsent'
              fallback='We sent you confirmation letter to email used for registration'
            />
            <br />
            <b>[{get(data, 'CreateUser.email', '')}]</b>
          </p>
        </div>
      ) : (
        <FormProvider
          formId='authentication'
          renderForm
          onSubmit={onSubmit}
          formClassName='RegisterForm card vertical-rhythm'
        >
          {!loading && error ? (
            <div className='error-message'>{error.toString()}</div>
          ) : null}
          <Field
            id='email'
            name='email'
            Component={TextInput}
            type='email'
            disabled={loading}
            label={<Text id='email' fallback='Email' />}
          />
          <Field
            id='firstname'
            name='firstname'
            Component={TextInput}
            type='text'
            disabled={loading}
            label={<Text id='fistname' fallback='Firstname' />}
          />
          <Field
            id='lastname'
            name='lastname'
            Component={TextInput}
            type='text'
            disabled={loading}
            label={<Text id='lastname' fallback='Lastname' />}
          />
          <Field
            id='organization'
            name='organization'
            Component={TextInput}
            type='text'
            disabled={loading}
            label={<Text id='organization' fallback='Organization' />}
          />
          <Field
            id='password'
            name='password'
            Component={TextInput}
            type='password'
            disabled={loading}
            label={<Text id='password' fallback='Password' />}
          />
          <div className='RegisterForm-controls'>
            <Button
              loading={loading}
              type='submit'
              className='RegisterForm-submit'
            >
              <Text id='register' fallback='Registration' />
              <b>&nbsp;&gt;</b>
            </Button>
          </div>
        </FormProvider>
      )}

      <div className='RegisterForm-adendum'>
        {loginPath ? (
          <Link to={loginPath}>
            <Text
              id='haveaccount'
              fallback='Already have an account? Login here.'
            />
          </Link>
        ) : null}
      </div>
    </section>
  );
}
