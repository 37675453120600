import logo from 'public/img/logo.svg';
import illustration from 'public/img/onboarding.svg';

import React from 'react';
import { Route } from 'react-router-dom';

import './style.css';

type AuthenticationScreenProps = {
  aside?: string;
  asideContent?: JSX.Element;
  children: React.ReactNode | React.ReactNodeArray;
};

export default function AuthenticationScreen(
  props: AuthenticationScreenProps
): JSX.Element {
  const { aside, children, asideContent } = props;
  return (
    <div
      className='AuthenticationScreen'
      style={{ '--AuthenticationScreen-aside': aside || undefined }}
    >
      <aside className='AuthenticationScreen-sidebar'>
        {asideContent}
        <img src={logo} className='AuthenticationScreen-logo' />
        <img src={illustration} className='AuthenticationScreen-illustration' />
      </aside>
      <main className='AuthenticationScreen-container'>{children}</main>
    </div>
  );
}
