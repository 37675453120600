import React, { useRef, useEffect, useCallback } from 'react';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin, faTrash } from '@fortawesome/free-solid-svg-icons';

import Button from 'src/Components/Button';

import './style.css';

type ThemeItemProps = {
  id: string;
  label: string;
  children?: React.ReactNode;
  className?: string;
  unsubscribeMessage: string;
  onUnsubscribe?: (arg: { variables: { id: string } }) => void;
  observerHandler: IntersectionObserver;
};

export default function ThemeItem(props: ThemeItemProps): JSX.Element {
  const {
    id,
    label,
    children,
    className,
    observerHandler,
    unsubscribeMessage,
    onUnsubscribe
  } = props;
  const $root = useRef<Element>();

  useEffect(() => {
    if ($root.current && observerHandler) {
      observerHandler.observe($root.current);
    }
  }, [observerHandler]);

  const handleUnsubscribe = useCallback(() => {
    if (onUnsubscribe) {
      if (window.confirm(unsubscribeMessage)) {
        onUnsubscribe({ variables: { id } });
      }
    }
  }, [onUnsubscribe, id, unsubscribeMessage]);

  return (
    <article className={cn('ThemeItem', className)} data-id={id} ref={$root}>
      <header className='ThemeItem-header'>
        <h1 className='ThemeItem-title' title={label}>
          {label}
        </h1>
        <nav className='ThemeItem-actions'>
          {/* <Button className='icon-only'>
            <FontAwesomeIcon icon={faMapPin} />
          </Button> */}
          {onUnsubscribe ? (
            <Button className='icon-only' onClick={handleUnsubscribe}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          ) : null}
        </nav>
      </header>
      {children}
    </article>
  );
}
