import React, { useCallback, useEffect, useState, useRef } from 'react';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';

import { useLazyQuery, useQuery } from '@apollo/client';
import { GetTrends } from 'src/apollo/queries/trend.graphql';
import { GetTrendLabels } from 'src/apollo/queries/trendlabel.graphql';

import Button from 'src/Components/Button';
import QueryStateMachine from 'src/Components/QueryStateMachine';
import NotFound from 'src/Components/NotFound';
import LoadingSpinner from 'src/Components/LoadingSpinner';

import Text from 'src/Text';
import useTranslation from 'src/Text/useTranslation';

import useBodyClick from 'src/hooks/useBodyClick';

import Trend from './Trend';

import './style.css';

type TrendsContainerProps = {
  theme_id: string;
  className?: string;
  load: boolean;
  region: string;
  segment_id: string;
  since: string;
  since_hours: number;
};

const SORT_OPTS = {
  mostrecent: { q: null },
  toprecent: { q: 20 },
  topalltime: { q: 9999, since: null, since_hours: null }
};

export default function TrendsContainer(
  props: TrendsContainerProps
): JSX.Element {
  const { className, theme_id, segment_id, load, since, since_hours } = props;

  const $hint = useRef();

  const HINT_TEXTS = {
    mostrecent: useTranslation('mostrecent', 'Example description 1'),
    toprecent: useTranslation('toprecent', 'Example description 2'),
    topalltime: useTranslation('topalltime', 'Example description 3')
  };

  const [value, setValue] = useState<string>('mostrecent');
  const [hint, setHint] = useState<boolean | null | string>(null);

  const setHintValue = useCallback(() => setHint(HINT_TEXTS[value]), [value]);

  const hideHint = useCallback(() => setHint(false), []);

  useBodyClick($hint, hideHint);

  const [loadTrends, { data, loading, called, error, refetch }] = useLazyQuery(
    GetTrends,
    {
      variables: { segment: segment_id, since, since_hours },
      nextFetchPolicy: 'network-only'
    }
  );
  const labelsQuery = useQuery(GetTrendLabels, { skip: !called });

  const items = data ? data.GetTrends : [];

  const labels =
    labelsQuery.called && !labelsQuery.loading && labelsQuery.data
      ? labelsQuery.data.GetTrendLabels
      : {};

  useEffect(() => {
    if (load && !called) {
      loadTrends();
    }
  }, [load, loadTrends, called]);

  const refreshQuery = useCallback(
    (e) => {
      e.preventDefault();
      const params = SORT_OPTS[e.target.value] || {};
      setValue(e.target.value);
      if (refetch) {
        refetch({ since, since_hours, ...params });
      }
    },
    [refetch, since, since_hours]
  );

  const mostrecent = useTranslation('mostrecenttrends', 'Most recent trends');
  const toprecent = useTranslation('toprecenttrends', 'Top recent trends');
  const topalltime = useTranslation('toptrendsalltime', 'Top trends all time');
  return (
    <div className={cn('TrendsContainer', className)}>
      <div className='TrendsContainer-header'>
        <form className='TrendsContainer-title'>
          <select
            name='sort'
            onChange={refreshQuery}
            className='TrendsContainer-sorting'
            value={value}
          >
            <option title={HINT_TEXTS.mostrecent} value='mostrecent'>
              {mostrecent}
            </option>
            <option title={HINT_TEXTS.toprecent} value='toprecent'>
              {toprecent}
            </option>
            <option title={HINT_TEXTS.topalltime} value='topalltime'>
              {topalltime}
            </option>
          </select>
        </form>
        <button
          ref={$hint}
          className='TrendsContainer-sorting-hint encircled-button'
          onClick={setHintValue}
        >
          ?
          {hint ? (
            <div className='TrendsContainer-sorting-hint-content'>{hint}</div>
          ) : null}
        </button>
        <Button
          className='TrendsContainer-refresh icon-only'
          title='Refresh this trends'
          onClick={refreshQuery}
        >
          <FontAwesomeIcon icon={faRedo} />
        </Button>
      </div>
      <ol
        className='TrendsContainer-list'
        style={{ '--TrendCounterWidth': `${items.length}`.length }}
      >
        <QueryStateMachine
          loading={loading}
          error={error}
          called={called}
          length={items.length}
          loadingComponent={
            <div className='TrendsContainer-loading'>
              <LoadingSpinner />
            </div>
          }
          errorComponent={
            <li className='error-message'>{error ? error.toString() : null}</li>
          }
          noDataComponent={
            <li className='no-items'>
              <NotFound imageAlt='Trends not found'>
                <Text
                  id='trendsnotfound'
                  fallback='Unfortunately we&amp;ve not found trends for this topic.'
                />
              </NotFound>
            </li>
          }
        >
          {items
            ? items.map(({ id, detected, label, isNew }) => (
                <li key={id}>
                  <Trend
                    id={id}
                    found={detected}
                    label={labels[id] || label}
                    theme_id={theme_id}
                    className={cn({ 'is-new': isNew })}
                  />
                </li>
              ))
            : null}
        </QueryStateMachine>
      </ol>
    </div>
  );
}
