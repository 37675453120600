import type { Article, QueryArticlesArgs } from '@schema-types';
import { decodeHtml } from 'src/util';
import baseApiClient from '../client';

const decoder = decodeHtml();

export const articleResolver = {
  Query: {
    Articles: async (_, params: QueryArticlesArgs): Promise<Article[]> => {
      const data = await baseApiClient('trendolizer_api', params);
      return data.map((item: Article) => ({
        ...item,
        title: decoder(item.title),
        image: decoder(item.image),
        description: decoder(item.description)
      }));
    }
  }
};
