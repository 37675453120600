import type {
  Theme,
  QueryThemeArgs,
  MutationSubscribeArgs,
  MutationUnsubscribeArgs,
  MutationMoveThemeArgs,
  BooleanOperation
} from '@schema-types';
import baseApiClient from '../client';

export const themeResolver = {
  Query: {
    Themes: (): Promise<Theme[]> =>
      baseApiClient('user/themes').then((data = []) =>
        data.map((item) => ({
          ...item,
          label: item.label || 'no label',
          name: item.name || 'no name'
        }))
      ),
    Theme: (_, { theme_id }: QueryThemeArgs): Promise<Theme> =>
      baseApiClient(`user/themes/${theme_id}`)
  },
  Mutation: {
    Subscribe: async (
      _,
      { id }: MutationSubscribeArgs
    ): Promise<BooleanOperation | { id: string }> => {
      await baseApiClient('user/theme/add', { id });
      return { success: true, id };
    },
    Unsubscribe: async (
      _,
      { id }: MutationUnsubscribeArgs
    ): Promise<BooleanOperation | { id: string }> => {
      await baseApiClient('user/theme/remove', { id });
      return { success: true, id };
    },
    MoveTheme: async (
      _,
      { item, target, mode }: MutationMoveThemeArgs
    ): Promise<BooleanOperation> => {
      await baseApiClient(`user/theme/move/${item}`, { [mode]: target });
      return { success: true };
    }
  }
};
