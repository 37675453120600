import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import Button from '../Button';

import './style.css';

type DialogProps = {
  id: string;
  open: boolean;
  title: React.ReactNode;
  className?: string;
  wrapContent?: boolean;
  children?: React.ReactNode;
  onClose: (e: React.MouseEvent) => void;
  width?: string;
  height?: string;
};

export default function Dialog(props: DialogProps) {
  const {
    id,
    title,
    className,
    open,
    children,
    width,
    height,
    wrapContent,
    onClose
  } = props;

  const onOverlayClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onClose(e);
    },
    [onClose]
  );

  const target = document.getElementById(id);

  if (!target) {
    throw new Error('Target element not found for a portal.');
  }

  const content = open ? (
    <div className='Dialog-backdrop layout-centered'>
      <div className='layout-ovelray' onClick={onOverlayClick} aria-hidden />
      <section
        className={cn('Dialog', className)}
        style={{
          '--Dialog-width': width || undefined,
          '--Dialog-height': height || undefined
        }}
      >
        <header className='Dialog-header'>
          <h1 className='Dialog-title'>{title}</h1>
          <Button
            title={`Close ${title} dialog`}
            className='Dialog-closebtn icon-only'
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </Button>
        </header>
        {wrapContent ? (
          <div className='Dialog-container'>{children}</div>
        ) : (
          children
        )}
      </section>
    </div>
  ) : null;

  return createPortal(content, target);
}
