import type { TrendLabel, MutationUpdateTrendLabelArgs } from '@schema-types';
import baseApiClient from '../client';

// await baseApiClient('user/trend_names');

export const trendLabelResolver = {
  Query: {
    GetTrendLabel: async (_, { id }): Promise<TrendLabel> => {
      const { label } = await baseApiClient(`user/trend/${id}`);
      return { id, label };
    },
    GetTrendLabels: async () => {
      const data = await baseApiClient('user/trend_names');
      return data.reduce((acc, { id, label }) => {
        acc[id] = label;
        return acc;
      }, {});
    }
  },
  Mutation: {
    UpdateTrendLabel: async (
      _,
      { id, label }: MutationUpdateTrendLabelArgs
    ): Promise<TrendLabel> => {
      await baseApiClient(`user/trend/${id}/rename`, {
        label
      });
      return { id, label };
    }
  }
};
