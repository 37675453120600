import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { LogOut } from 'src/apollo/mutations/user.graphql';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import Button from 'src/Components/Button';
import Text from 'src/Text';

export default function LogoutButton() {
  const [onClick, { loading }] = useMutation(LogOut);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      onClick();
    },
    [onClick]
  );

  return (
    <Button
      title='Log out'
      loading={loading}
      className='LogoutButton'
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faSignOutAlt} />
      <span>
        <Text id='logout' fallback='Log out' />
      </span>
    </Button>
  );
}
