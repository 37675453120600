import React from 'react';
import { useField } from './hooks';
import type { FieldProps } from './types';

export default function Field(props: FieldProps) {
  const { id, name, Component, defaultValue, children, ...rest } = props;

  const field = useField(name, defaultValue);

  return (
    <Component
      disabled={field.disabled}
      {...rest}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      id={id}
    >
      {children}
    </Component>
  );
}
