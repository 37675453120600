import { userResolver } from './user';
import { userAlertsResolver } from './useralerts';
import { themeResolver } from './themes';
import { trendResolver } from './trends';
import { trendLabelResolver } from './trendlabels';
import { articleResolver } from './articles';
import { opinionResolver } from './opinions';
import { hierarchyResolver } from './hierarchy';

export const resolvers = [
  userResolver,
  themeResolver,
  trendResolver,
  trendLabelResolver,
  articleResolver,
  opinionResolver,
  hierarchyResolver,
  userAlertsResolver
];
