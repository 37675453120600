import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { TranslationContext, ProviderProps } from './types';

// Create context instance
// ======================================================================
export const Context = React.createContext<TranslationContext>({
  getEntry: () => null
});
Context.displayName = 'TranslationContext';

// React context providing translation vocabulary for <Text> clients
// ======================================================================
export default function TranslationProvider(props: ProviderProps) {
  const { children, locale } = props;

  const [dictionary, setDictionary] = useState<object | null>(null);

  // Dynamically fetch dictionary in relation to URL slug
  // chunk synced asyncronously
  // ======================================================================
  const getDictionary = useCallback(async (locale) => {
    const module = await import(`translations/${locale}.json`);
    setDictionary(module.default);
  }, []);

  // Run it once on component mount
  // ======================================================================
  useEffect(() => {
    if (locale) {
      getDictionary(locale);
    }
  }, [locale]);

  // Context value cached through [useMemo]
  // ======================================================================
  const contextValue: TranslationContext = useMemo(
    () => ({
      getEntry: (key) => (dictionary ? dictionary[key] || null : '\u00A0')
    }),
    [dictionary]
  );

  // Return provider instance with context value given
  // ======================================================================
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
