import React, { useState } from 'react';

import Text from 'src/Text';

import StatementTable from './StatementTable';
import TimeseriesGraphs from './TimeseriesGraphs';
import TopOpinions from './TopOpinions';

import './style.css';

type OpinionsProps = {
  theme_id?: string;
  trend_id?: string;
};

export default function Opinions(props: OpinionsProps): JSX.Element {
  const { trend_id } = props;
  const [selected, setSelected] = useState<string | null>(null);

  return (
    <div className='Opinions layout-grid'>
      <TopOpinions
        title={<Text id='topopinions' fallback='Top Opinions' />}
        selected={selected}
        name={trend_id}
        onItemClick={setSelected}
        className='layout-cell-50'
      />
      <div className='layout-cell-50'>
        {selected ? <TimeseriesGraphs opinion={selected} /> : null}
      </div>
      <div className='Opinions-tablecell layout-cell-100'>
        {selected ? <StatementTable opinion={selected} /> : null}
      </div>
    </div>
  );
}
