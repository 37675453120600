import React from 'react';

import DelayedRender from '../DelayedRender';

type QueryStateMachineProps = {
  loading?: boolean;
  error?: string | Error;
  called?: boolean;
  length?: number;
  loadingComponent: React.ReactNode;
  errorComponent: React.ReactNode;
  noDataComponent: React.ReactNode;
  children: React.ReactNode;
};

export default function QueryStateMachine(props: QueryStateMachineProps) {
  const {
    loading,
    error,
    called,
    length,
    loadingComponent,
    errorComponent,
    noDataComponent,
    children
  } = props;

  const loaded = called && !loading;

  const loadedSuccessfully = loaded && !error;

  return (
    <DelayedRender trigger={!!loading} component={loadingComponent}>
      {loaded && error ? errorComponent : null}
      {loadedSuccessfully && !length ? noDataComponent : null}
      {loadedSuccessfully && length ? children : null}
    </DelayedRender>
  );
}
