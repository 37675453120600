/**
 * Simple handler to check whatever given values is a simple Object
 * @param {Any} arg Value to test
 * @returns {Boolean}
 */
export function isPlainObject(arg: any): boolean {
  if (arg === null || Array.isArray(arg)) {
    return false;
  }
  return (
    typeof arg === 'object' && Object.getPrototypeOf(arg) === Object.prototype
  );
}

/**
 * Run function if it exists. Just helper to eliminate common conditions
 * @param {Function} func proposed function
 * @param  {...any} args arguments for function
 */
export function runIfExists(func, ...args) {
  if (typeof func === 'function') {
    return func(...args);
  }
  return undefined;
}

/**
 * lodash-like property getter, works with nested objects
 * @param {Object} obj target object to pick a property from
 * @param {String} path key descriptor . dot marks nested object property
 * @param {Any} fallback fallback value used if key is not found
 */
export function get(target: object, path: string, fallback?: any): any {
  const trace = path.split('.');
  let result = target;
  let i = 0;

  while (
    i < trace.length &&
    result !== null &&
    result !== undefined &&
    Object.hasOwnProperty.call(result, trace[i])
  ) {
    result = result[trace[i]];
    i += 1;
  }
  return i === trace.length ? result : fallback;
}

/**
 * lodash-like property setter, works with nested objects
 * @param {Object} obj target object to pick a property from
 * @param {String} path key descriptor . dot marks nested object property
 * @param {Any} value new value of given property
 */
export function set(obj: object, path: string, value: any): object {
  const trace = path.split('.');
  const key = trace.pop();
  let target = obj;

  let i = 0;
  const { length } = trace;

  while (target && i < length) {
    if (target[trace[i]] === undefined) {
      target[trace[i]] = {};
    }
    target = target[trace[i]];
    i += 1;
  }
  if (isPlainObject(target) && key !== undefined) {
    target[key] = value;
  }
  return obj;
}

/**
 * Simple function that allows to pick subset of data from Object given as rest agruments as key descriptors
 * @param {Object} object target object to pick data from
 * @param {...String} paths keys or path of keys descriptions to pick from
 * @return {Object}
 */
export function pick(target: object, ...paths: string[]): object {
  let i = -1;
  const result = {};

  // eslint-disable-next-line no-plusplus
  while (++i < paths.length) {
    const value = target[paths[i]];
    if (value !== undefined) {
      result[paths[i]] = value;
    }
  }
  return result;
}

/**
 * Generic debounce handler implementation
 * @param {Function} func Function to debounce
 * @param {Number} wait Timeout in ms between invokations
 * @param {Boolean} immediate Whatever function should fire immideately upon first inst
 */
export function debounce(
  func: (...args: any) => void,
  wait: number,
  immediate?: boolean
): any {
  let timeout;
  return (...args) => {
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) return func(...args);
    }, wait);
    if (callNow) return func(...args);
  };
}

/**
 * Get numeric css value from a computed styles of page
 * @param {String} name Name of css var `--` may be omitted
 * @param {Number} fallback Fallback value if value not found
 */
export function getCssVal(name: string, fallback?: string): string | undefined {
  return (
    getComputedStyle(document.documentElement).getPropertyValue(`--${name}`) ||
    fallback
  );
}

/**
 * Simple function to extract `number` from a `string` or fallback value if none found
 * @param {String} str source string as parse object
 * @param {Number} fallback fallback value if parse result is `NaN`
 */
export function parseNum(str: string, fallback: number): number {
  const numVal = parseInt(str, 10);
  return Number.isNaN(numVal) ? fallback : numVal;
}

export function decodeHtml() {
  const txt = document.createElement('textarea');
  return (html?: string | null): string => {
    txt.innerHTML = html || '';
    return txt.value;
  };
}

export function unsetInitLoading() {
  document.body.classList.remove('is-loading');
}

export function throwIfError(data) {
  if (data.error) {
    throw new Error(data.error);
  }
  return data;
}

export function toggleArrayValue(arr: any[], val: any): any[] {
  const set = new Set(arr);
  if (set.has(val)) {
    set.delete(val);
  } else {
    set.add(val);
  }
  return [...set];
}

export function getHost(str: string, fallback?: string | null): string | null {
  try {
    const { host } = new URL(str);
    return host;
  } catch {
    return fallback || null;
  }
}
