import React, { useCallback } from 'react';
import cn from 'classnames';

import { useQuery, useMutation } from '@apollo/client';
import { GetUser } from 'src/apollo/queries/user.graphql';
import { GetThemes } from 'src/apollo/queries/theme.graphql';
import { EditDashboardMode } from 'src/apollo/mutations/user.graphql';

import { get } from 'src/util';
import useTranslation from 'src/Text/useTranslation';
import Text from 'src/Text';

type ThemesSortingDropdownProps = {
  id: string;
  disabled?: boolean;
  className?: string;
};

export default function ThemesSortingDropdown(
  props: ThemesSortingDropdownProps
): JSX.Element {
  const { id, className, disabled } = props;

  const { data, loading } = useQuery(GetUser);
  const [editDashboardMode] = useMutation(EditDashboardMode, {
    refetchQueries: [GetThemes, GetUser]
  });
  const value = get(data, 'User.dashboard_mode', '');

  const my_themes = useTranslation('themesmyorder', 'My themes (my order)');
  const my_themes_alphabetic = useTranslation(
    'themesalphabeticalorder',
    'My themes (alphabetical order)'
  );
  const my_themes_with_trends = useTranslation(
    'themesmynewestorder',
    'My themes (with newest trends first)'
  );
  const all_themes_with_trends = useTranslation(
    'themeswiththemesnewestfirst',
    'All themes with trends, newest first'
  );

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
      await editDashboardMode({
        variables: { dashboard_mode: event.target.value }
      });
    },
    [editDashboardMode]
  );

  return (
    <form className={cn('ThemesSortingDropdown', className)}>
      <label>
        <Text id='dashboardmode' fallback='Dashboard mode' />
        <select
          disabled={disabled}
          name={id}
          onChange={handleChange}
          value={value}
        >
          <option value='' disabled hidden>
            {loading ? 'Loading...' : ''}
          </option>
          <option value='my_themes'>{my_themes}</option>
          <option value='my_themes_alphabetic'>{my_themes_alphabetic}</option>
          <option value='my_themes_with_trends'>{my_themes_with_trends}</option>
          <option value='all_themes_with_trends'>
            {all_themes_with_trends}
          </option>
        </select>
      </label>
    </form>
  );
}
