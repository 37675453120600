const MONTHS_FULL = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'Novermber',
  'December'
];

const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
const WEEKDAYS_FULL = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const decZero = (num: number): string => (num < 10 ? `0${num}` : `${num}`);

const DATE_FORMAT_DICTIONARY = {
  YYYY: (date: Date): string => `${date.getFullYear()}`,
  YY: (date: Date): string => date.getFullYear().substr(-2, 2),
  MMMM: (date: Date): string => MONTHS_FULL[date.getMonth()],
  MMM: (date: Date): string => MONTHS_SHORT[date.getMonth()],
  MM: (date: Date): string => decZero(date.getMonth() + 1),
  M: (date: Date): string => `${date.getMonth() + 1}`,
  DD: (date: Date): string => decZero(date.getDate()),
  D: (date: Date): string => `${date.getDate()}`,
  dddd: (date: Date): string => WEEKDAYS_FULL[date.getDay()],
  ddd: (date: Date): string => WEEKDAYS_SHORT[date.getDay()],
  HH: (date: Date): string => decZero(date.getHours()),
  H: (date: Date): string => `${date.getHours()}`,
  hh: (date: Date): string => {
    /** Not sure */
    const hours = date.getHours();
    return decZero((hours > 12 ? hours - 12 : hours) + 1);
  },
  h: (date: Date): string => {
    /** Not sure */
    const hours = date.getHours();
    return `${(hours > 12 ? hours - 12 : hours) + 1}`;
  },
  mm: (date: Date): string => decZero(date.getMinutes()),
  m: (date: Date): string => `${date.getMinutes()}`,
  ss: (date: Date): string => decZero(date.getSeconds()),
  s: (date: Date): string => `${date.getSeconds()}`,
  Z: (date: Date): string => `${date.getTimezoneOffset() / 60}:00`
};

const DATE_FORMAT_REGEX = new RegExp(
  Object.keys(DATE_FORMAT_DICTIONARY).join('|'),
  'g'
);

/**
 * Robust date display option. It takes a string of tokens and replaces them with their corresponding values from a date given.
 * @param {Date} date date as a data source
 * @param {String} format format string
 */
export default function formatDate(date: Date, format: string) {
  return format.replace(DATE_FORMAT_REGEX, (match) => {
    if (DATE_FORMAT_DICTIONARY[match]) {
      return DATE_FORMAT_DICTIONARY[match](date);
    }
    return match;
  });
}
