/* tslint:disable */
// This file was automatically generated
// ============================================================
export const typeDefs = `
type Article {
  hash: ID!
  title: String!
  domain: String!
  url: String!
  image: String
  description: String
  likes: Int
  rate_likes: Int
}

extend type Query {
  Articles(keywords: String!, order: String!): [Article!]!
  Article(hash: String!): Article!
}

scalar JSON
scalar JSONObject

schema {
  query: Query
  mutation: Mutation
}

type Query {
  _: Boolean
}

type Mutation {
  _: Boolean
}

type BooleanOperation {
  success: Boolean
}

type Hierarchy {
  id: ID!
  label: String!
  children: [Hierarchy]
  segment_id: String!
  subscribed: Int!
}

extend type Query {
  GetHierarchy(region_id: String!, brand_id: String!): [Hierarchy!]!
}

type Opinion {
  id: ID!
  label: String!
  timeseries: JSONObject
  first: String!
  last: String!
  total: Int!
}

type OpinionCluster {
  identifier: ID!
  statement_text: String!
  language: String!
  url: String!
  polarity: Float!
  type: String!
  cluster_id: Int!
  index: Int!
  content: String!
  sentiment: String!
  subjectivity: Float!
  publish_time: String!
  ingest_time: String!
  topics: [String]
}

extend type Query {
  GetTopOpinions(name: String!): [Opinion!]!
  GetOpinionCluster(id: String!): [OpinionCluster!]!
}

type Theme {
  theme_id: ID!
  label: String!
  name: String!
  segment_id: String!
}

type SubscribeSuccess {
  success: Boolean!
  id: String!
}

extend type Query {
  Themes: [Theme!]!
  Theme(theme_id: String!): Theme!
}

extend type Mutation {
  Subscribe(id: String!): SubscribeSuccess!
  Unsubscribe(id: String!): SubscribeSuccess!
  MoveTheme(item: String!,target: String!, mode: String!): BooleanOperation
}

"""
type Segment {
  id: ID!
  long_name: String!
  region: String!
  region_name: String!
  short_name: String!
  theme: String!
  theme_name: String!
}
"""
type Trend {
  id: ID!
  detected: String!
  isNew: Boolean
  theme_id: String!
  label: String!
  theme_stats: JSONObject
  region_stats: JSONObject
  region: String!
}

extend type Query {
  GetTrends(
    thema: String
    since: String
    since_hours: Int
    segment: String!
    region: String
    q: Int
  ): [Trend!]!
  GetTrend(id: String!): Trend
}

type TrendLabel {
  id: ID!
  label: String!
}

extend type Query {
  GetTrendLabel(id: String!): TrendLabel!
  GetTrendLabels: JSONObject!
}

extend type Mutation {
  UpdateTrendLabel(id: String!, label: String!): TrendLabel!
}

type User {
  id: Int
  firstname: String
  lastname: String
  email: String!
  organization: String!
  language: String!
  profile: String!
  region: String!
  since: String
  dashboard_mode: String
  admin: Boolean!
  has_crowdtangle: Boolean!
  since_hours: Int
  slack_hook: String
}
type LogOut {
  User: User
  Themes: [Theme]
  Trends: [Trend]
}

extend type Query {
  User: User!
  UnauthorizedUser: User!
  DashboardMode: User!
}

extend type Mutation {
  CreateUser(
    email: String!
    password: String!
    firstname: String!
    lastname: String!
    organization: String!
  ): BooleanOperation!
  LogIn(email: String!, password: String!): BooleanOperation!
  LogOut: LogOut
  EditUser(
    email: String
    firstname: String
    lastname: String
    organization: String
    since_hours: Int
    language: String
    slack_hook: String
    profile: String
    region: String
  ): User!
  EditDashboardMode(dashboard_mode: String): User
}

type UserAlerts {
  daily: Int
  email_active: Int
  last_sent_daily: String
  last_sent_realtime: String
  realtime: Int
  slack_active: Int
  user_id: Int
}

extend type Query {
  UserAlerts: UserAlerts
}

extend type Mutation {
  ActivateChannel(channel: String!): BooleanOperation!
  DeactivateChannel(channel: String!): BooleanOperation!
  ChangeMethod(method: String!): BooleanOperation!
}

`;
/* tslint:enable */
