import type { TextProps, TranslationContext } from './types';
import React, { useContext } from 'react';
import { Context } from './Provider';

// Translation node instance
// Requires [TranslationContext] in order to work
// ======================================================================
export default function Text({ id, fallback }: TextProps) {
  const { getEntry } = useContext<TranslationContext>(Context);
  const result = getEntry(id);
  return <>{result || fallback}</>;
}
