import React, { useCallback, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { useLazyQuery } from '@apollo/client';
import { GetTrendLabel } from 'src/apollo/queries/trendlabel.graphql';

import Dialog from 'src/Components/Dialog';
import Tabs from 'src/Components/Tabs';

import { get } from 'src/util';

import TrendOverview from './TrendOverview';
import Opinions from './Opinions';

import './style.css';

type TrendsDetailContainerProps = {
  id: string;
  width?: string;
  height?: string;
  backroute: string;
};

const TABS = ['trendnewstitle', 'opinionstitle'];

export default function TrendsDetailContainer(
  props: TrendsDetailContainerProps
): JSX.Element {
  const { id, width, height, backroute } = props;
  const match = useRouteMatch('/trend/:theme/:id') || {};
  const { push } = useHistory();

  const trend_id = get(match, 'params.id', null);
  const theme_id = get(match, 'params.theme', null);

  const [fetchLabel, { data, loading }] = useLazyQuery(GetTrendLabel);
  const label = get(
    data,
    'GetTrendLabel.label',
    loading ? 'Loading...' : 'Unknown label'
  );

  useEffect(() => {
    if (trend_id) {
      fetchLabel({ variables: { id: trend_id } });
    }
  }, [fetchLabel, trend_id]);

  const closeDialog = useCallback(() => push(backroute), [push, backroute]);

  return (
    <Dialog
      id={id}
      open={!!trend_id}
      width={width}
      height={height}
      wrapContent={false}
      className='TrendsDetails'
      title={label}
      onClose={closeDialog}
    >
      <Tabs renderMenu={TABS}>
        <TrendOverview trend_id={trend_id} />
        <Opinions trend_id={trend_id} theme_id={theme_id} />
      </Tabs>
    </Dialog>
  );
}
