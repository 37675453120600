import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { GetUser } from 'src/apollo/queries/user.graphql';
import { $region } from 'src/apollo/vars';

import AuthenticationScreen from './AuthenticationScreen';
import LoginForm from './AuthenticationScreen/LoginForm';
import RegisterForm from './AuthenticationScreen/RegisterForm';
import ThemasSetup from './AuthenticationScreen/ThemasSetup';
import RegionSelect from './AuthenticationScreen/RegionSelect';
import RegistrationSuccess from './AuthenticationScreen/RegistrationSuccess';

import DashboardScreen from './DashboardScreen';

import TranslationProvider from './Text/Provider';

import { get, unsetInitLoading } from 'src/util';

let locale = window.location.pathname.split('/')[1];
if (locale.length !== 2) {
  locale = 'en';
}

const LOCALES = {
  English: 'en',
  Dutch: 'nl'
};

export default function Application(): JSX.Element {
  const { data, loading, called } = useQuery(GetUser, {
    errorPolicy: 'ignore',
    onCompleted: unsetInitLoading
  });

  const id = get(data, 'User.id', null);
  const userLocale = LOCALES[get(data, 'User.language', null)] || locale;

  const loaded = called && !loading;

  useEffect(() => {
    if (userLocale !== locale) {
      window.location.pathname = window.location.pathname.replace(
        `/${locale}`,
        `/${userLocale}`
      );
    }
  }, [userLocale]);

  return (
    <TranslationProvider locale={loaded ? locale : null}>
      {loaded ? (
        <BrowserRouter basename={`/${locale}`}>
          <Switch>
            <Route path='/login'>
              <AuthenticationScreen>
                <LoginForm registerPath='/register' />
                {id ? <Redirect to='/' /> : null}
              </AuthenticationScreen>
            </Route>
            <Route path='/register'>
              <AuthenticationScreen>
                <RegisterForm loginPath='/login' />
                {id ? <Redirect to='/' /> : null}
              </AuthenticationScreen>
            </Route>
            <Route path='/success'>
              <AuthenticationScreen>
                <RegistrationSuccess />
              </AuthenticationScreen>
            </Route>
            <Route path='/setup'>
              <AuthenticationScreen
                aside='25%'
                asideContent={<RegionSelect onChange={$region} />}
              >
                <ThemasSetup regionVar={$region} />
              </AuthenticationScreen>
            </Route>
            <Route path='*'>
              <DashboardScreen />
              {!id ? <Redirect to='/login' /> : null}
            </Route>
          </Switch>
        </BrowserRouter>
      ) : null}
    </TranslationProvider>
  );
}
