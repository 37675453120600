import { throwIfError } from 'src/util';
import { BASE_URL } from 'src/const';

export default function baseApiClient<O, P>(
  url: string,
  params?: P
): Promise<O> {
  const query = Object.entries(params || {})
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  return fetch(`${BASE_URL}/${url}${query.length ? `?${query}` : ''}`, {
    mode: 'cors',
    credentials: 'same-origin'
  })
    .then((res) => res.json())
    .then(throwIfError);
}

export function trendsClient<O>({ id = null, ...params } = {}): Promise<O> {
  let url = 'trends_api?call=/trends';
  if (id) {
    url += `/${id}`;
  }
  return baseApiClient<O>(url, params);
}

export const trendsv2Client = ({ id = null, ...params } = {}): Promise<O> => {
  let url = 'trends_api?call=/v2/trends';
  if (id) {
    url += `/${id}`;
  }
  return baseApiClient<O>(url, params);
};

export const opinionsClient = (
  url: string,
  params?: Record<string, number>
): Promise<O> => {
  return baseApiClient<O>(`opinions_api?call=/${url}`, params);
};
