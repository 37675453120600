import type { Hierarchy, QueryGetHierarchyArgs } from '@schema-types';
import baseApiClient from '../client';

export const hierarchyResolver = {
  Query: {
    GetHierarchy: async (
      _,
      params: QueryGetHierarchyArgs
    ): Promise<Hierarchy[]> => baseApiClient('theme_hierarchy', params)
  }
};
