import type { Theme } from '@schema-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from 'react-grid-dnd';

import { useQuery, useMutation } from '@apollo/client';
import { GetThemes } from 'src/apollo/queries/theme.graphql';
import { Unsubscribe, MoveTheme } from 'src/apollo/mutations/theme.graphql';
import useTranslation from 'src/Text/useTranslation';

import ThemeItem from '../ThemeItem';
import TrendsContainer from '../TrendsContainer';

import { debounce, get } from 'src/util';
import { CUSTOM_ORDER_KEY } from 'src/const';

import './style.css';

type ThemesContainerProps = {
  className?: string;
  region: string;
  since: string;
  since_hours: number;
  dndEnabled?: boolean;
  dashboard_mode?: string;
};

const getMeasurements = () => {
  return {
    count: Math.max(1, Math.floor(window.outerWidth / 620)),
    height: Math.max(385, Math.round(window.outerHeight * 0.35))
  };
};

export default function ThemesContainer(
  props: ThemesContainerProps
): JSX.Element {
  const { className, region, dashboard_mode, since, since_hours, dndEnabled } =
    props;

  const [itemsInOrder, setOrder] = useState<Theme[]>([]);
  const [{ height, count }, setDimensions] = useState(getMeasurements());
  const { push } = useHistory();

  const onWindowResize = useMemo(
    () =>
      debounce(() => {
        setDimensions(getMeasurements());
      }, 100),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
  }, [onWindowResize]);

  const { loading, error } = useQuery(GetThemes, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: useCallback(
      (data) => {
        setOrder(data.Themes);
        if (dashboard_mode === CUSTOM_ORDER_KEY && !data.Themes.length) {
          push('/setup');
        }
      },
      [dashboard_mode, push]
    )
  });

  const [themeUnsubscribe] = useMutation(Unsubscribe, {
    refetchQueries: [GetThemes],
    onCompleted: useCallback((data = {}) => {
      const deleted = get(data, 'Unsubscribe.id');
      setOrder((old) => old.filter(({ segment_id }) => segment_id !== deleted));
    }, [])
  });

  const [moveTheme] = useMutation(MoveTheme);

  const [loaded, setLoaded] = useState<Record<string, boolean>>({});

  const observer = useMemo(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      const interseced = {};
      entries.forEach(({ isIntersecting, target }) => {
        interseced[target.dataset.id] = isIntersecting;
      });
      setLoaded((old) => ({ ...old, ...interseced }));
    };
    const obs = new IntersectionObserver(callback, { threshold: 0.1 });
    return obs;
  }, []);

  const onDNDChange = useCallback(async (_, sourceIndex, targetIndex) => {
    try {
      let item, target;
      setOrder((old) => {
        item = (old[sourceIndex] || {}).segment_id;
        target = (old[Math.max(targetIndex - 1, 0)] || {}).segment_id;
        return swap(old, sourceIndex, targetIndex);
      });
      if (item !== undefined && target !== undefined) {
        await moveTheme({
          variables: {
            mode: targetIndex > 0 ? 'after' : 'before',
            item,
            target
          }
        });
      }
    } catch (err) {
      alert(
        "Attept to reorder themes failed. Order wouldn't be saved after page reload."
      );
    }
  }, []);

  const unsubscribeConfirmation = useTranslation(
    'doyouwantunsubscribe',
    "Do you really wan't to unsubscribe from this theme?"
  );
  return (
    <section className={cn('ThemesContainer', className)}>
      {!loading && error ? (
        <div className='error-message'>{error.toString()}</div>
      ) : null}
      {!loading ? (
        <GridContextProvider onChange={onDNDChange}>
          <GridDropZone
            id='themes-dnd'
            className='ThemesContainer-content'
            boxesPerRow={count}
            rowHeight={height}
            disableDrag={!dndEnabled}
            disableDrop={!dndEnabled}
          >
            {itemsInOrder.map(({ theme_id, segment_id, label }) => (
              <GridItem key={theme_id} className='ThemesContainer-dnditem'>
                <ThemeItem
                  id={segment_id}
                  label={label}
                  observerHandler={observer}
                  unsubscribeMessage={unsubscribeConfirmation}
                  onUnsubscribe={themeUnsubscribe}
                >
                  <TrendsContainer
                    theme_id={theme_id}
                    segment_id={segment_id}
                    load={loaded[theme_id]}
                    className='ThemeItem-content'
                    region={region}
                    since={since}
                    since_hours={since_hours}
                  />
                </ThemeItem>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      ) : null}
    </section>
  );
}
