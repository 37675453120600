// import reg_success from 'public/img/reg_success.svg';

import React from 'react';
import { Link } from 'react-router-dom';

import Text from 'src/Text';

import './style.css';

export default function RegistrationSuccess() {
  return (
    <div className='RegistrationSuccess'>
      <div className='RegistrationSuccess-content'>
        <h3>
          <Text
            id='youraccountcreated'
            fallback='Your account has been created'
          />
        </h3>
        <p>
          <Text
            id='youremailconfirmed'
            fallback='Your email has been confirmed'
          />
        </p>
        <p>
          <Link to='/'>
            <Text id='gotoapp' fallback='Go to application' />
          </Link>
        </p>
      </div>
    </div>
  );
}
