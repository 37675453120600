import React, { useCallback } from 'react';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faArrowDown,
  faExclamationCircle,
  faMinus
} from '@fortawesome/free-solid-svg-icons';

import Text from 'src/Text';

import './style.css';

export type EntityStatus = 'rising' | 'lowering' | 'neutral' | 'standout';

export type EntityProps = {
  id: string | number;
  value: string;
  count?: number;
  classNames?: string;
  selected?: boolean;
  onClick: (e: string) => void;
  status?: EntityStatus;
};

const STATUS_MAP = {
  rising: [faArrowUp, 'This item mentioning in increasing'],
  lowering: [faArrowDown, 'This item mentioning is decreasing'],
  neutral: [faMinus, 'This item has no significant changes in usage'],
  standout: [
    faExclamationCircle,
    'This item appeared recently and quickly becomes popular'
  ]
};

export default function Entity(props: EntityProps): JSX.Element {
  const { value, id, count, status, selected, classNames, onClick } = props;

  const handleClick = useCallback<React.MouseEventHandler<HTMLLIElement>>(
    (e: React.MouseEvent): void => {
      e.preventDefault();
      onClick(id);
    },
    [onClick, id]
  );

  const [icon, title] = STATUS_MAP[status || 'neutral'] || STATUS_MAP.neutral;

  return (
    <li
      className={cn('Entity', { 'is-selected': selected }, classNames)}
      onClick={handleClick}
    >
      <span className={`Entity-status is-${status || 'neutral'}`} title={title}>
        <FontAwesomeIcon icon={icon} />
      </span>
      <b className='Entity-value'>{value}</b>
      {count ? (
        <span className='Entity-count'>
          ({count} <Text id='mentions' fallback='mentions' />)
        </span>
      ) : null}
      {status === 'standout' ? (
        <span className='Entity-breakout'>Breakout</span>
      ) : null}
    </li>
  );
}
