import React, { useCallback, useState, useRef } from 'react';
import cn from 'classnames';
import useBodyClick from 'src/hooks/useBodyClick';

import Button from '../Button';

import './style.css';

type MenuProps = {
  children: React.ReactNodeArray;
  buttonContent: JSX.Element;
  className?: string;
  title?: string;
};

export default function Menu(props: MenuProps): JSX.Element {
  const { className, title, children, buttonContent } = props;
  const $root = useRef<React.RefObject<Element>>();
  const [open, setOpen] = useState<boolean>(false);

  const showContent = useCallback(() => setOpen(true), []);
  const hideContent = useCallback(() => setOpen(false), []);

  useBodyClick($root, hideContent);

  return (
    <div className={cn('Menu', className)} ref={$root}>
      <Button className='Menu-button' title={title} onClick={showContent}>
        {buttonContent}
      </Button>
      {open ? <div className='Menu-popup'>{children}</div> : null}
    </div>
  );
}
