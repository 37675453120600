import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useApolloClient, gql } from '@apollo/client';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';

import './style.css';

type TimeseriesGraphsProps = {
  opinion?: string;
};

type Opinion = {
  first: number;
  last: number;
  arrayData: { time: number; value: number }[];
};

const OpinionFragment = gql`
  fragment MyOpinion on Opinion {
    id
    label
    total
    timeseries
    first
    last
  }
`;

const timeAxisFormatter = (data) => {
  return new Date(data).toLocaleString();
};

export default function TimeseriesGraphs(
  props: TimeseriesGraphsProps
): JSX.Element {
  const { opinion } = props;
  const $root = useRef() as React.MutableRefObject<HTMLDivElement>;
  const client = useApolloClient();
  const [data, setData] = useState<Opinion | null>(null);
  const [[width, height], setDimensions] = useState([0, 0]);

  useEffect(() => {
    if (opinion) {
      const data = client.readFragment({
        id: `Opinion:${opinion}`,
        fragment: OpinionFragment
      });
      const first = new Date(data.first).getTime() - 86400000; // Minus one day
      const last = new Date(data.last).getTime() + 86400000; // Plus one day
      const arrayData = Object.entries(data.timeseries)
        .map(([time, amount]) => ({
          time: new Date(time).getTime(),
          amount
        }))
        .filter(({ time }) => time === time);
      arrayData.unshift({ time: first, amount: 0 });
      arrayData.push({ time: last, amount: 0 });
      setData({
        ...data,
        first: first || 0,
        last: last || 0,
        arrayData
      });
    }
  }, [opinion, client]);

  useLayoutEffect(() => {
    if ($root && $root.current) {
      setDimensions([
        $root.current.parentElement
          ? $root.current.parentElement.clientWidth
          : 0,
        $root.current.parentElement
          ? $root.current.parentElement.clientHeight
          : 0
      ]);
    }
  }, []);

  return (
    <div className='TimeseriesGraphs' ref={$root}>
      <LineChart
        width={width}
        height={height}
        data={data ? data.arrayData : []}
      >
        <Line type='monotone' dataKey='amount' stroke='var(--accent-color)' />
        <XAxis
          type='number'
          domain={[data ? data.first : 0, data ? data.last : 0]}
          dataKey='time'
          scale='time'
          hasTicks
          interval='preserveStartEnd'
          tickFormatter={timeAxisFormatter}
        />
        <YAxis dataKey='amount' />
        <Tooltip labelFormatter={timeAxisFormatter} />
        <Legend />
      </LineChart>
    </div>
  );
}
