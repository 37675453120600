import type {
  Opinion,
  OpinionCluster,
  QueryGetTopOpinionsArgs,
  QueryGetOpinionClusterArgs
} from '@schema-types';
import { opinionsClient } from '../client';

const dict = 'abcdefghijklmnopqrstvuwxyzABCDEFGHIJKLMNOPQRSTVUWXYZ0123456789';

function uniqueId(...scheme: number[]): string {
  return scheme
    .map((num) => {
      let result = '';
      while (result.length <= num) {
        result += dict[Math.round(Math.random() * (dict.length - 0) + 0)];
      }
      return result;
    })
    .join('-');
}

function reduceTimeseries(timeseries) {
  return timeseries.reduce(
    (acc, amount, index) => {
      if (amount !== 0) {
        acc[index] = amount;
      }
      return acc;
    },
    { length: timeseries.length }
  );
}

export const opinionResolver = {
  Query: {
    GetTopOpinions: async (
      _,
      { name }: QueryGetTopOpinionsArgs
    ): Promise<Opinion[]> => {
      const data = await opinionsClient(
        `top-opinion-clusters-by-trend/${name}/`
      );
      return data;
    },
    GetOpinionCluster: async (
      _,
      { id }: QueryGetOpinionClusterArgs
    ): Promise<OpinionCluster[] | null> => {
      const data = await opinionsClient(`cluster-details/${id}`);
      return data.map((item: OpinionCluster) => ({
        ...item,
        sentiment:
          item.polarity > 0 ? 'positive' : item.polarity < 0 ? 'negative' : ''
      }));
    }
  },
  Mutation: {}
};
