import logo from 'public/img/logo.svg';

import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { GetUser } from 'src/apollo/queries/user.graphql';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faTh, faBars } from '@fortawesome/free-solid-svg-icons';

import Button from 'src/Components/Button';
import Menu from 'src/Components/Menu';
import ThemesSortingDropdown from 'src/Components/ThemesSorting';
import Text from 'src/Text';

import Greeting from './Greeting';
import ThemesContainer from './ThemesContainer';
import LogoutButton from './LogoutButton';
import TrendsDetailContainer from './TrendDetailsContainer';
import UserSettings from './UserSettings';
import GoogleProvider from './GoogleProvider';

import { get, debounce } from 'src/util';
import { CUSTOM_ORDER_KEY } from 'src/const';

import './style.css';

const invert = (val: boolean): boolean => !val;

export default function DashboardScreen(): JSX.Element {
  const $header = useRef<React.RefObject<HTMLDivElement>>();
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [sorting, setSorting] = useState<boolean>(false);
  const { data } = useQuery(GetUser, {
    errorPolicy: 'ignore'
  });

  const dashboard_mode = get(data, 'User.dashboard_mode', '');

  const onContainerScroll = useMemo(
    () =>
      debounce((e: Event) => {
        if ($header && $header.current) {
          const scroll = e.target ? e.target.scrollTop : 0;
          $header.current.classList.toggle(
            'container-scrolled',
            !!(scroll > window.outerHeight * 0.3)
          );
        }
      }, 100),
    []
  );

  const toggleSettings = useCallback((e) => {
    e.preventDefault();
    setShowSettings(invert);
  }, []);

  const toggleCustomOrder = useCallback(() => {
    setSorting(invert);
  }, []);

  return (
    <GoogleProvider pack='corechart' apiGetter='google.visualization.LineChart'>
      <div className='DashboardScreen'>
        <header ref={$header} className='DashboardScreen-header'>
          <img src={logo} className='DashboardScreen-logo' />
          <Greeting
            name={get(data, 'User.firstname', '')}
            profile={get(data, 'User.profile', '')}
            organization={get(data, 'User.organization', '')}
          />
          <Menu
            className='DashboardScreen-header-menu'
            buttonContent={<FontAwesomeIcon icon={faBars} />}
            title='Open settings'
          >
            <Link to='/setup' className='like-button'>
              <FontAwesomeIcon icon={faTh} />
              <span>
                <Text id='configurethemes' fallback='Configure themes' />
              </span>
            </Link>
            <ThemesSortingDropdown id='theme-sorting' />
            {dashboard_mode === CUSTOM_ORDER_KEY ? (
              <label className='DashboardScreen-header-togglednd'>
                <input
                  type='checkbox'
                  data-switcher
                  checked={sorting}
                  name='custom-sorting'
                  onChange={toggleCustomOrder}
                />
                {sorting ? (
                  <Text
                    id='disableddragdrop'
                    fallback='Disable drag &amp; drop'
                  />
                ) : (
                  <Text id='enabledragdrop' fallback='Enable drag &amp; drop' />
                )}
              </label>
            ) : null}
            <Button onClick={toggleSettings}>
              <FontAwesomeIcon icon={faCog} />
              <span>
                <Text id='usersettings' fallback='User settings' />
              </span>
            </Button>
            <LogoutButton />
          </Menu>
        </header>
        <main
          onScroll={onContainerScroll}
          className='DashboardScreen-container'
        >
          <ThemesContainer
            dndEnabled={sorting}
            dashboard_mode={dashboard_mode}
            region={get(data, 'User.region', '')}
            since={get(data, 'User.since', '')}
            since_hours={get(data, 'User.since_hours', 24)}
          />
        </main>
        <TrendsDetailContainer
          id='trend'
          width='94vw'
          height='80vh'
          backroute='/'
        />
        <UserSettings
          id='settings'
          title={<Text id='usersettings' fallback='User settings' />}
          open={showSettings}
          onClose={toggleSettings}
        />
      </div>
    </GoogleProvider>
  );
}
