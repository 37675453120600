import React from 'react';
import cn from 'classnames';

import { useQuery } from '@apollo/client';
import { GetTopOpinions } from 'src/apollo/queries/opinion.graphql';

import QueryStateMachine from 'src/Components/QueryStateMachine';
import NotFound from 'src/Components/NotFound';
import LoadingSpinner from 'src/Components/LoadingSpinner';
import Entity from 'src/Components/Entity';
import Text from 'src/Text';

import { get } from 'src/util';

import './style.css';

type TopOpinionsProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  name?: string;
  className?: string;
  selected?: string | null;
  onItemClick: (value: string) => void;
};

export default function TopOpinions(props: TopOpinionsProps): JSX.Element {
  const { title, subtitle, name, className, selected, onItemClick } = props;
  const { data, loading, error, called } = useQuery(GetTopOpinions, {
    skip: !name,
    variables: {
      name
    }
  });

  const items = get(data, 'GetTopOpinions', []);

  return (
    <section className={cn('TopOpinions', className)}>
      <header className='TopOpinions-header'>
        <h1 className='TopOpinions-title'>{title}</h1>
        {subtitle ? <h2 className='TopOpinions-subtitle'>{subtitle}</h2> : null}
      </header>
      <div className='TopOpinions-container'>
        <ol className='TopOpinions-list'>
          <QueryStateMachine
            loading={loading}
            error={error}
            called={called}
            length={items.length}
            loadingComponent={
              <div className='TrendsContainer-loading'>
                <LoadingSpinner />
              </div>
            }
            errorComponent={
              <li className='error-message'>
                {error ? error.toString() : null}
              </li>
            }
            noDataComponent={
              <li className='no-items'>
                <NotFound imageAlt='Trends not found'>
                  <Text
                    id='trendsnotfound'
                    fallback='Unfortunately we&amp;ve not found trends for this topic.'
                  />
                </NotFound>
              </li>
            }
          >
            {items
              ? items.map(({ id, label, total }) => (
                  <Entity
                    key={id}
                    id={id}
                    value={label}
                    status='neutral'
                    count={total}
                    selected={!!(selected && selected === id)}
                    onClick={onItemClick}
                  />
                ))
              : null}
          </QueryStateMachine>
        </ol>
      </div>
    </section>
  );
}
