import React from 'react';

import DelayedRender from '../DelayedRender';
import LoadingSpinner from '../LoadingSpinner';

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
  form?: string;
  disabled?: boolean;
  title?: string;
  onClick?: React.MouseEventHandler;
};

export default function Button(props: ButtonProps) {
  const { type, children, loading, disabled, className, ...rest } = props;
  return (
    <button
      {...rest}
      type={type}
      data-loading={loading ? true : undefined}
      className={className}
    >
      <DelayedRender trigger={!!loading} component={<LoadingSpinner />}>
        {children}
      </DelayedRender>
    </button>
  );
}
