import React from 'react';
import cn from 'classnames';

import './style.css';

type LoadingSpinnerProps = {
  className?: string;
};

export default function LoadingSpinner(props: LoadingSpinnerProps) {
  const { className } = props;
  return (
    <svg className={cn('LoadingSpinner', className)} viewBox='0 0 50 50'>
      <circle
        className='LoadingSpinner-path'
        cx='25'
        cy='25'
        r='20'
        fill='none'
        strokeWidth='5'
      ></circle>
    </svg>
  );
}
