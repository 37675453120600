import React, { useCallback } from 'react';
import cn from 'classnames';

import './style.css';

type TagProps = {
  onClick?: (value: string) => void;
  value: string;
  title?: string;
  className?: string;
};

export default function Tag(props: TagProps) {
  const { onClick, title, className, value } = props;

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      if (onClick) {
        onClick(value);
      }
    },
    [onClick, value]
  );

  return (
    <span
      className={cn('Tag', { 'is-clickable': !!onClick }, className)}
      title={title}
      onClick={handleClick}
    >
      {value}
    </span>
  );
}
