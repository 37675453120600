import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';

import { useMutation } from '@apollo/client';
import { UpdateTrendLabel } from 'src/apollo/mutations/trendlabel.graphql';
import { GetTrendLabels } from 'src/apollo/queries/trendlabel.graphql';

import Text from 'src/Text';
import QuickEditForm from 'src/Components/QuickEditForm';
import Button from 'src/Components/Button';
import formatDate from 'src/formatDate';

import './style.css';

type TrendProps = {
  id: string;
  found: string;
  theme_id: string;
  label: string;
  className?: string;
};

const invert = (v) => !v;

export default function Trend(props: TrendProps): JSX.Element {
  const { id, found, theme_id, label, className } = props;
  const [state, setState] = useState(false);
  const [onSubmit, edit] = useMutation(UpdateTrendLabel, {
    refetchQueries: [GetTrendLabels]
  });

  const handleSubmit = useCallback(
    async ({ variables }) => {
      await onSubmit({
        variables: {
          ...variables,
          id
        }
      });
      setState(false);
    },
    [onSubmit, id]
  );

  const toggleState = useCallback(() => setState(invert), []);

  return (
    <div className={cn('Trend', className, { 'state-open': state })}>
      {state ? (
        <QuickEditForm
          name='label'
          onSubmit={handleSubmit}
          className='Trend-form'
          loading={edit.loading}
          placeholder={label}
          submitText={<Text id='submit' fallback='Submit' />}
        />
      ) : (
        <Link
          to={`/trend/${theme_id}/${id}`}
          title={label}
          className='Trend-link'
        >
          <b className='Trend-title'>{label}</b>
          <time className='Trend-found' dateTime={found}>
            ({formatDate(new Date(found), 'DD-MM-YYYY HH:MM')})
          </time>
        </Link>
      )}
      <Button className='Trend-statechange icon-only' onClick={toggleState}>
        <FontAwesomeIcon icon={state ? faTimes : faPen} />
      </Button>
    </div>
  );
}
