import notfound from 'public/img/notfound.svg';

import React from 'react';
import cn from 'classnames';

import './style.css';

type NotFoundProps = {
  imageAlt: string;
  className?: string;
  title?: React.ReactNode;
  children: React.ReactNode;
};

export default function NotFound(props: NotFoundProps) {
  const { imageAlt, className, children } = props;
  return (
    <div className={cn('NotFound', className)}>
      <img className='NotFound-image' src={notfound} alt={imageAlt} />
      {children}
    </div>
  );
}
