import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faArrowDown,
  faMinus
} from '@fortawesome/free-solid-svg-icons';

import Text from 'src/Text';

import { useQuery } from '@apollo/client';
import { GetOpinionCluster } from 'src/apollo/queries/opinion.graphql';

import QueryStateMachine from 'src/Components/QueryStateMachine';
import LoadingSpinner from 'src/Components/LoadingSpinner';
import NotFound from 'src/Components/NotFound';

import { get, getHost } from 'src/util';

import './style.css';

const STATUS_MAP = {
  positive: faArrowUp,
  negative: faArrowDown
};

type Statement = {
  id: string;
  fullstatement: string;
  sentiment: string;
  media: string;
  source: string;
};

type StatementTableProps = {
  className?: string;
  opinion: string | number;
};

export default function StatementTable(props: StatementTableProps) {
  const { opinion, className } = props;

  const [sort, setSort] = useState('');

  const { data, loading, error, called } = useQuery(GetOpinionCluster, {
    skip: !opinion,
    variables: {
      id: opinion
    }
  });

  const items = get(data, 'GetOpinionCluster', []);

  const theadClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setSort(e.target.dataset.sort);
  }, []);

  return (
    <table className={cn('StatementTable', className)}>
      <colgroup>
        <col style={{ width: '50%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '20%' }} />
      </colgroup>
      <thead>
        <tr>
          <th
            className='StatementTable-header'
            data-sort=''
            onClick={theadClick}
          >
            <Text id='fullstatements' fallback='Full statements' />
          </th>
          <th
            className='StatementTable-header'
            data-sort='sentiment'
            onClick={theadClick}
          >
            <Text id='sentiment' fallback='Sentiment' />
          </th>
          <th
            className='StatementTable-header'
            data-sort='type'
            onClick={theadClick}
          >
            <Text id='media' fallback='Media' />
          </th>
          <th
            className='StatementTable-header'
            data-sort='source'
            onClick={theadClick}
          >
            <Text id='source' fallback='Source' />
          </th>
        </tr>
      </thead>
      <tbody>
        <QueryStateMachine
          loading={loading}
          error={error}
          called={called}
          length={items.length}
          loadingComponent={
            <tr>
              <td className='StatementTable-loading' colSpan={4}>
                <LoadingSpinner />
              </td>
            </tr>
          }
          errorComponent={
            <tr>
              <td className='StatementTable-error' colSpan={4}>
                <div className='error-message'>
                  {error ? error.toString() : null}
                  {/* error ? error.toString() : null */}
                </div>
              </td>
            </tr>
          }
          noDataComponent={
            <tr>
              <td className='StatementTable-noitems' colSpan={4}>
                <NotFound imageAlt='There are no statements found at the moment'>
                  <Text
                    id='statementsnotfound'
                    fallback='There are no statements found at the moment'
                  />
                </NotFound>
              </td>
            </tr>
          }
        >
          {items
            ? items.map(
                ({
                  cluster_id,
                  index,
                  statement_text,
                  sentiment,
                  type,
                  url
                }) => (
                  <tr
                    key={`${cluster_id}=${index}`}
                    className={cn('StatementTable-row', {
                      [`status-${sentiment}`]: sentiment
                    })}
                  >
                    <td>{statement_text}</td>
                    <td className={`StatementTable-status status-${sentiment}`}>
                      <FontAwesomeIcon
                        icon={STATUS_MAP[sentiment] || faMinus}
                      />
                      {sentiment}
                    </td>
                    <td>{type}</td>
                    <td>
                      {url ? (
                        <a
                          href={url}
                          target='_blank'
                          rel='noreferrer'
                          className='StatementTable-source'
                        >
                          {getHost(url)}
                        </a>
                      ) : null}
                    </td>
                  </tr>
                )
              )
            : null}
        </QueryStateMachine>
      </tbody>
    </table>
  );
}
