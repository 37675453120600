import React, { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { GetTrend } from 'src/apollo/queries/trend.graphql';
import { GetArticles } from 'src/apollo/queries/article.graphql';

import Text from 'src/Text';

import TrendWords from './TrendWords';
import RelatedNews from './RelatedNews';
import TrendolizerMetrics from './TrendolizerMetrics';

import { get, toggleArrayValue } from 'src/util';

import './style.css';

type TrendOverviewProps = {
  trend_id?: string;
};

type SelectedState = {
  init: boolean;
  loading: string | null;
  order: 'engagement' | 'latest';
  keywords: string[];
};

const getLoadingStep = (w: string[]): SelectedState => ({
  init: true,
  order: 'engagement',
  loading: `Loading articles for: ${w.join(',')}`,
  keywords: w
});

export default function TrendOverview(props: TrendOverviewProps): JSX.Element {
  const { trend_id } = props;

  const [{ init, keywords, order, loading }, setSelected] =
    useState<SelectedState>({
      init: true,
      loading: null,
      order: 'engagement',
      keywords: []
    });

  const [selectedArticle, setSelectedArticle] = useState<string | null>(null);

  const toggleSelectedWord = useCallback(
    (value) =>
      setSelected(({ order, keywords }) => ({
        init: false,
        loading: null,
        order,
        keywords: toggleArrayValue(keywords, value)
      })),
    []
  );

  const onOrderChange = useCallback(
    (e) =>
      setSelected((old) => ({
        ...old,
        init: false,
        loading: null,
        order: e.target.value
      })),
    []
  );

  const articlesQuery = useQuery(GetArticles, {
    skip: init
  });

  const fetchArticles = articlesQuery.refetch;
  const runInitFetch = useCallback(
    async ({ keywords, order }) => {
      const { data } = await fetchArticles({
        keywords: keywords.join(','),
        order
      });
      if ((data && data.Articles.length > 0) || !keywords.length) {
        setSelected({
          init: false,
          loading: null,
          order: 'engagement',
          keywords
        });
        setSelectedArticle(data.Articles[0].hash);
        return data.Articles;
      } else {
        setSelected(getLoadingStep(keywords));
        return runInitFetch({
          keywords: keywords.slice(0, -1),
          order
        });
      }
    },
    [fetchArticles]
  );

  const onTrendCompleted = useCallback(
    ({ GetTrend }) => {
      const keywords = Object.keys(GetTrend.theme_stats).sort(
        (a, b) => GetTrend.theme_stats[b] - GetTrend.theme_stats[a]
      );
      runInitFetch({ keywords, order });
      setSelected(getLoadingStep(keywords));
    },
    [order, runInitFetch]
  );

  const trendsQuery = useQuery(GetTrend, {
    skip: !trend_id,
    onCompleted: onTrendCompleted,
    variables: {
      id: trend_id
    }
  });

  useEffect(() => {
    if (!init) {
      fetchArticles({
        keywords: keywords.join(','),
        order
      });
    }
  }, [init, order, keywords, fetchArticles]);

  return (
    <div className='TrendOverview layout-grid'>
      <TrendWords
        className='layout-cell-50'
        selected={keywords.join(',')}
        loading={trendsQuery.loading}
        error={trendsQuery.error}
        called={trendsQuery.called}
        data={get(trendsQuery.data, 'GetTrend.theme_stats', {})}
        title={<Text id='topwords' fallback='Top Trend Words' />}
        subtitle={<Text id='select' fallback='Select a word' />}
        onItemClick={!init ? toggleSelectedWord : undefined}
      />
      <RelatedNews
        title={<Text id='newsfeed' fallback='News Feed' />}
        className='layout-cell-50'
        onSelectedClick={toggleSelectedWord}
        onOrderChange={onOrderChange}
        loading={loading || articlesQuery.loading}
        error={articlesQuery.error}
        called={articlesQuery.called}
        data={get(articlesQuery.data, 'Articles', [])}
        selected={keywords}
        selectedArticle={selectedArticle}
        onItemClick={setSelectedArticle}
      />
      <TrendolizerMetrics
        hash={selectedArticle}
        className='layout-cell-50'
        fields='likes,shares,views,tweets'
      >
        <Text id='loadinggraphs' fallback='Detailed data is loading' />
      </TrendolizerMetrics>
    </div>
  );
}
