import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import './style.css';

type QuickEditFormProps = {
  name: string;
  loading?: boolean;
  error?: string;
  className?: string;
  placeholder?: string;
  submitText: React.ReactNode;
  onSubmit: (arg: any) => void;
};

export default function QuickEditForm(props: QuickEditFormProps) {
  const { onSubmit, name, loading, className, placeholder, submitText } = props;

  const [value, setValue] = useState('');

  const onInputChange = useCallback((e) => setValue(e.target.value), []);

  const handleInput = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit({ variables: { [name]: value } });
    },
    [onSubmit, name, value]
  );

  return (
    <form className={cn('QuickEditForm', className)} onSubmit={handleInput}>
      <input
        type='text'
        name={name}
        value={value}
        className='QuickEditForm-input'
        disabled={loading}
        onChange={onInputChange}
        placeholder={placeholder}
      />
      <button className='QuickEditForm-submit' disabled={loading}>
        {submitText}
      </button>
    </form>
  );
}
