import 'public/css/app.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';

import { createApolloClient } from './apollo';

import Application from './Router';

ReactDOM.render(
  <ApolloProvider client={createApolloClient()}>
    <Application />
  </ApolloProvider>,
  document.getElementById('root')
);
