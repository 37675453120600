import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { useQuery, useReactiveVar, useMutation } from '@apollo/client';
import { GetThemes } from 'src/apollo/queries/theme.graphql';
import { Subscribe, Unsubscribe } from 'src/apollo/mutations/theme.graphql';
import type { ReactiveVar } from '@apollo/client';
import { GetHierarchy } from 'src/apollo/queries/hierarchy.graphql';

import { get } from 'src/util';

import { Link } from 'react-router-dom';
import QueryStateMachine from 'src/Components/QueryStateMachine';
import NotFound from 'src/Components/NotFound';
import LoadingSpinner from 'src/Components/LoadingSpinner';
import type { RegionVar } from 'src/apollo/vars';

import Text from 'src/Text';

import './style.css';

type HierarchyItem = {
  id: string;
  label: string;
  segment_id: string;
  children?: HierarchyItem[] | null;
};

const mapToIds = (acc, { theme_id, segment_id }) => {
  acc[theme_id] = segment_id;
  return acc;
};

const getLength = (arr: HierarchyItem[]): number =>
  arr.reduce((acc: number, { children }) => {
    acc += children ? getLength(children) : 1;
    return acc;
  }, 0);

const getSelectedChildren = (arr, selected): boolean => {
  if (!arr) return false;
  return arr.reduce((acc, item) => {
    if (acc) return acc;
    return item.children
      ? getSelectedChildren(item.children, selected)
      : !!selected[item.id];
  }, false);
};

function renderSubtree(items, name, onChange, selected): JSX.Element {
  return (
    <ul className='ThemasSetup-list'>
      {items.map(({ segment_id, label, children }) => (
        <li key={segment_id} className='ThemasSetup-item'>
          <label>
            <input
              type='checkbox'
              value={segment_id}
              name={name}
              checked={!!selected[segment_id]}
              onChange={onChange}
            />
            {label}
          </label>
          {children ? renderSubtree(children, name, onChange, selected) : null}
        </li>
      ))}
    </ul>
  );
}

type TopLevelThemeProps = {
  id: string;
  label: string;
  count?: number | null;
  name: string;
  selected?: boolean;
  childrenSelected?: boolean;
  children?: JSX.Element | null;
  onChange: (e: React.MouseEvent<HTMLInputElement>) => Promise<void>;
};

function TopLevelTheme(props: TopLevelThemeProps): JSX.Element {
  const {
    id,
    label,
    name,
    onChange,
    count,
    children,
    selected,
    childrenSelected
  } = props;

  return (
    <details
      className={cn('ThemasSetup-primary', {
        'state-selected': childrenSelected || selected
      })}
    >
      <summary>
        <b>{label}</b>
        <input
          type='checkbox'
          data-switcher
          name={name}
          value={id}
          checked={selected}
          onChange={onChange}
        />
        {children ? (
          <small>
            {count} <Text id='subthemas' fallback='Subthema&amp;s' />
          </small>
        ) : null}
      </summary>
      {children}
    </details>
  );
}

type ThemasSetupProps = {
  regionVar: ReactiveVar<RegionVar>;
  name?: string;
};

export default function ThemasSetup(props: ThemasSetupProps): JSX.Element {
  const { regionVar, name = 'thema' } = props;
  const [selected, setSelected] = useState({});

  const { region_id, brand_id } = useReactiveVar(regionVar);

  useQuery(GetThemes, {
    onCompleted: useCallback(({ Themes }) => {
      setSelected(Themes.reduce(mapToIds, {}));
    }, [])
  });

  const { called, data, loading, refetch, error } = useQuery(GetHierarchy, {
    fetchPolicy: 'no-cache',
    skip: !region_id,
    variables: { region_id, brand_id }
  });

  const [themeSubscribe, subscribe] = useMutation(Subscribe);
  const [themeUnsubscribe, unsubscribe] = useMutation(Unsubscribe);

  const onChange = useCallback(
    async (e: React.MouseEvent<HTMLInputElement>): Promise<void> => {
      const target = e.target as HTMLInputElement;
      const id = target.value;
      if (target.checked) {
        await themeSubscribe({
          variables: { id }
        });
        setSelected((prevState) => ({
          ...prevState,
          [id]: true
        }));
      } else {
        await themeUnsubscribe({ variables: { id } });
        setSelected((prevState) => {
          delete prevState[id];
          return { ...prevState };
        });
      }
    },
    [themeSubscribe, themeUnsubscribe]
  );

  const items = get(data, 'GetHierarchy', []);

  useEffect(() => {
    refetch({ region_id, brand_id });
  }, [refetch, region_id, brand_id]);

  return (
    <section className='ThemasSetup'>
      <header className='ThemasSetup-header'>
        <h1 className='ThemasSetup-title'>
          <Text id='chooseyourthemes' fallback='Choose your themes' />
        </h1>
        <h2 className='ThemasSetup-subtitle'>
          <Text
            id='choosethemassubthemas'
            fallback='Choose different themes sub-themes here'
          />
        </h2>
        <Link to='/' className='ThemasSetup-back like-button'>
          <Text id='done' fallback='Done' />
          <b>&nbsp;&gt;</b>
        </Link>
      </header>
      <form
        className={cn('ThemasSetup-content', { 'no-content': !items.length })}
      >
        <QueryStateMachine
          loading={loading}
          error={error}
          called={called}
          length={items.length}
          loadingComponent={
            <div className='ThemasSetup-loading'>
              <LoadingSpinner />
            </div>
          }
          errorComponent={
            <div className='ThemasSetup-error error-message'>
              {error ? error.toString() : null}
            </div>
          }
          noDataComponent={
            <div className='ThemasSetup-notfound no-items'>
              <NotFound imageAlt='Theme hierarchy not found'>
                <Text
                  id='hierarchynotfound'
                  fallback='Unfortunately we&amp;ve not found trends for this topic.'
                />
              </NotFound>
            </div>
          }
        >
          {items
            ? items
                .reduce(
                  (
                    acc,
                    { segment_id, label, children }: HierarchyItem,
                    i: number
                  ) => {
                    const index = i % 3;
                    acc[index].push(
                      <TopLevelTheme
                        key={segment_id}
                        id={segment_id}
                        name={name}
                        label={label}
                        childrenSelected={getSelectedChildren(
                          children,
                          selected
                        )}
                        selected={!!selected[segment_id]}
                        onChange={onChange}
                        count={children ? getLength(children) : null}
                      >
                        {children
                          ? renderSubtree(children, name, onChange, selected)
                          : null}
                      </TopLevelTheme>
                    );
                    return acc;
                  },
                  [[], [], []]
                )
                .map((childs: HierarchyItem[], i: number) => (
                  <div key={`col-${i}`} className='ThemasSetup-column'>
                    {childs}
                  </div>
                ))
            : null}
        </QueryStateMachine>
      </form>
    </section>
  );
}
