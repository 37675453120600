import { makeVar } from '@apollo/client';

export type RegionVar = { region_id: string | null; brand_id: string | null };

export const $region = makeVar<RegionVar>({
  region_id: null,
  brand_id: null
});

export const $dndenabled = makeVar<boolean>(false);
