import type {
  UserAlerts,
  MutationActivateChannelArgs,
  MutationDeactivateChannelArgs,
  MutationChangeMethodArgs,
  BooleanOperation
} from '@schema-types';
import baseApiClient from '../client';

export const userAlertsResolver = {
  Query: {
    UserAlerts: (): Promise<UserAlerts> => baseApiClient('user/alerts')
  },
  Mutation: {
    ActivateChannel: async (
      _,
      { channel }: MutationActivateChannelArgs
    ): Promise<BooleanOperation> => {
      await baseApiClient(`user/alerts/channel/${channel}/activate`);
      return { success: true };
    },
    DeactivateChannel: async (
      _,
      { channel }: MutationDeactivateChannelArgs
    ): Promise<BooleanOperation> => {
      await baseApiClient(`user/alerts/channel/${channel}/deactivate`);
      return { success: true };
    },
    ChangeMethod: async (
      _,
      { method }: MutationChangeMethodArgs
    ): Promise<BooleanOperation> => {
      await baseApiClient(`user/alerts/${method}/activate`);
      return {
        success: true
      };
    }
  }
};
