import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useCallback
} from 'react';
import cn from 'classnames';
import Text from '../../Text';

import Button from '../Button';

import { getCssVal, parseNum } from 'src/util';

import './style.css';

type TabsProps = {
  className?: string;
  children: React.ReactNodeArray;
  active?: number;
  renderMenu: string[] | null;
  itemClassName?: string;
};

const TD = parseInt(getCssVal('td') || '') || 250;

export default function Tabs(props: TabsProps) {
  const { className, children, active, itemClassName, renderMenu } = props;
  const initIndex = active || 0;
  const $el = useRef<React.RefObject<HTMLElement>>();
  const prevTab = useRef<React.RefObject<number>>(initIndex);
  const [activeTab, setActiveTab] = useState<number>(initIndex);

  useEffect(() => {
    if (typeof active === 'number') {
      setActiveTab(active);
    }
  }, [active]);

  useLayoutEffect(() => {
    if ($el.current && activeTab !== prevTab.current) {
      $el.current.classList.add(
        activeTab < prevTab.current ? 'slide-left' : 'slide-right'
      );
      setTimeout(() => {
        $el.current.classList.remove('slide-left');
        $el.current.classList.remove('slide-right');
      }, TD);
    }
    prevTab.current = activeTab;
  }, [activeTab]);

  const buttonClick = useCallback(
    (e: Event): void => {
      e.preventDefault();
      const i = parseNum(e.target.dataset.index, 0);
      if (i !== activeTab) {
        setActiveTab(i);
      }
    },
    [activeTab]
  );

  return (
    <div className={cn('Tabs', className)}>
      {renderMenu ? (
        <nav className='Tabs-menu'>
          {renderMenu.map((title, i) => (
            <Button
              key={`tab ${i}`}
              data-index={i}
              className={cn({ 'state-active': activeTab === i })}
              onClick={buttonClick}
            >
              <Text id={title} fallback={`Tab ${i}`} />
            </Button>
          ))}
        </nav>
      ) : null}
      <div className='Tabs-container'>
        <div
          ref={$el}
          data-tab={activeTab}
          className={cn('Tabs-item', itemClassName)}
        >
          {children[activeTab]}
        </div>
      </div>
    </div>
  );
}

Tabs.defaultProps = {
  renderMenu: true
};
