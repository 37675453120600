import React from 'react';
import cn from 'classnames';

import Entity from 'src/Components/Entity';

import './style.css';

interface WordsData {
  [key: string]: number;
}

type TrendWordsProps = {
  id?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  className?: string;
  selected?: string;
  data: WordsData;
  loading?: boolean;
  error?: Error;
  called?: boolean;
  onItemClick?: (value: string) => void;
};

const sortByValue = ([, a], [, b]) => b - a;

export default function TrendWords(props: TrendWordsProps): JSX.Element {
  const { title, subtitle, selected, className, data, onItemClick } = props;

  return (
    <section className={cn('TrendWords', className)}>
      <header className='TrendWords-header'>
        <h1 className='TrendWords-title'>{title}</h1>
        {subtitle ? <h2 className='TrendWords-subtitle'>{subtitle}</h2> : null}
      </header>
      <div className='TrendWords-container'>
        <ol className='TrendWords-list'>
          {Object.entries(data)
            .sort(sortByValue)
            .map(([value, count]) => {
              return (
                <Entity
                  key={value}
                  id={value}
                  value={value}
                  count={count}
                  selected={!!(selected && selected.includes(value))}
                  status='neutral'
                  onClick={onItemClick}
                />
              );
            })}
        </ol>
      </div>
    </section>
  );
}
