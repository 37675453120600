import React, {
  useRef,
  useContext,
  useEffect,
  useCallback,
  useState
} from 'react';
import cn from 'classnames';
import baseApiClient from 'src/apollo/client';
import { Context } from '../../../GoogleProvider';

type TrendolizerMetricsProps = {
  className?: string;
  children?: React.ReactNode;
  hash: string | null;
  fields: string;
};

const GRAPH_OPTIONS = {
  interpolateNulls: true,
  backgroundColor: 'transparent',
  theme: 'material',
  explorer: {
    actions: ['dragToZoom', 'rightClickToReset']
  },
  annotation: {
    style: 'line'
  },
  curveType: 'function',
  chartArea: {
    backgroundColor: 'transparent',
    top: 'auto',
    left: 40,
    height: '90%',
    width: '100%'
  },
  pointSize: 3,
  legend: {
    position: 'bottom'
  }
};

function fetchData(hash, type) {
  return baseApiClient('trendolizer_graphdata', {
    hash,
    type
  });
}

function mapGraphGoogle(result, data, fields, opts) {
  const types = fields.split(',');
  const sum = {};
  const avgCnt = {};
  const previous = {};
  const movVals = {};
  let movCnt = 0;
  let annotation;
  let annotationText;
  const dateExp = /\d{4}\/\d{2}\/\d{2}\s\d{2}:\d{2}:\d{2}/;

  // Create DataTable and make Datetimes column
  // ===========================================================================
  let datetimes = Object.keys(data)
    .filter((k) => k && k.length)
    .sort();
  result.addColumn('datetime', null);
  if (opts.limit) datetimes = datetimes.splice(-opts.limit, opts.limit);

  // Add Columns for each required Mesaurement type
  // ===========================================================================
  types.forEach((type, i) => {
    const rate = `Rate ${type}`;
    if (!opts.nocount) {
      result.addColumn('number', type);
      if (i === 0) {
        result.addColumn({ type: 'string', role: 'annotation' });
        result.addColumn({ type: 'string', role: 'annotationText' });
      }
    }
    if (!opts.norate) result.addColumn('number', rate);
    if (!opts.noaverage) result.addColumn('number', `Average ${rate}`);
    if (!opts.nomovingaverage)
      result.addColumn('number', `Moving average ${rate}`);
    if (!opts.nochangerate) result.addColumn('number', `Change ${rate}`);
    movVals[`rate_${type}`] = [];
  });

  datetimes
    .filter((v) => dateExp.test(v))
    .forEach((dt) => {
      const row = [new Date(`${dt} UTC`)];

      types.forEach((rowType, j) => {
        const rr = `rate_${rowType}`;
        const rV = parseInt(data[dt][rr]);

        if (!opts.nocount) {
          row.push(parseInt(data[dt][rowType]));
          if (j === 0) {
            row.push(annotation);
            row.push(annotationText);
          }
        }

        if (!opts.norate) row.push(rV);

        if (!opts.noaverage) {
          if (isNaN(data[dt][rr])) {
            row.push(null);
          } else {
            sum[rr] = sum[rr] ? sum[rr] + rV : rV;
            avgCnt[rr] = avgCnt[rr] ? avgCnt[rr] + 1 : 1;
            row.push(Math.round(sum[rr] / avgCnt[rr]));
          }
        }

        if (!opts.nomovingaverage) {
          if (isNaN(data[dt][rr])) {
            row.push(null);
          } else {
            movVals[rr].push(data[dt][rr] ? data[dt][rr] : 0);
            movCnt += 1;

            if (movCnt > opts.movWindow) {
              movVals[rr].shift();
              movCnt = opts.movWindow;
            }

            row.push(
              Math.round(
                movVals[rr].reduce((acc, mv) => {
                  acc += mv;
                  return acc;
                }, 0) / movCnt
              )
            );
          }
        }

        if (!opts.nochangerate) {
          if (isNaN(previous[rr])) previous[rr] = 0;
          if (isNaN(data[dt][rr])) {
            row.push(null);
          } else {
            row.push(rV - parseInt(previous[rr]));
            previous[rr] = data[dt][rr];
          }
        }
      });

      annotation = '';
      annotationText = '';
      if (data[dt].found) {
        annotation = data[dt].found;
        annotationText = `Found: ${new Date(`${dt} UTC`)}`;
      } else {
        result.addRow(row);
      }
    });
  return result;
}

function drawChart($el, Api, data, fields) {
  const chart = new Api($el);
  const datatable = new window.google.visualization.DataTable();
  chart.draw(mapGraphGoogle(datatable, data, fields, {}), {
    ...GRAPH_OPTIONS,
    width: $el.clientWidth,
    height: $el.clientHeight
  });
}

export default function TrendolizerMetrics(
  props: TrendolizerMetricsProps
): JSX.Element {
  const { children, hash, fields, className } = props;
  const $root = useRef();
  const { Api, error } = useContext(Context);

  const [state, setState] = useState<string | boolean>(false);

  const makeGraph = useCallback(
    async (id) => {
      try {
        setState(true);
        const data = await fetchData(id, fields);
        drawChart($root.current, Api, data, fields);
        setState(false);
      } catch (err) {
        setState(err.toString());
      }
    },
    [fields, Api, $root]
  );

  useEffect(() => {
    if (Api && hash) {
      makeGraph(hash);
    }
  }, [makeGraph, Api, hash]);

  return (
    <div ref={$root} className={cn('TrendolizerMetrics', className)}>
      {error || typeof state === 'string' ? (
        <div className='error-message'>{error || state}</div>
      ) : (
        children
      )}
    </div>
  );
}
