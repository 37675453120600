import React, { useEffect, useCallback } from 'react';

export default function useBodyClick(
  $el: React.RefObject<Element>,
  callback: React.MouseEventHandler
): void {
  const handler = useCallback(
    (event) => {
      if ($el.current && !$el.current.contains(event.target)) {
        callback(event);
      }
    },
    [callback, $el]
  );

  useEffect(() => {
    document.body.addEventListener('click', handler);
    return () => {
      document.body.removeEventListener('click', handler);
    };
  }, [handler]);
}
