import type {
  User,
  LogOut,
  BooleanOperation,
  MutationEditUserArgs,
  MutationCreateUserArgs
} from '@schema-types';
import baseApiClient from '../client';
import type { LogInVariables } from '../mutations/__generated__/LogIn';

export const userResolver = {
  Query: {
    User: async (): Promise<User> => {
      const data = await baseApiClient('user');
      return {
        ...data,
        admin: !!data.admin,
        has_crowdtangle: !!data.has_crowdtangle
      };
    }
  },
  Mutation: {
    CreateUser: async (
      _,
      params: MutationCreateUserArgs
    ): Promise<BooleanOperation> => {
      await baseApiClient('user/add', params);

      return { success: true };
    },
    EditUser: async (_, params: MutationEditUserArgs): Promise<User> => {
      await baseApiClient('user/update', params);
      return params;
    },
    EditDashboardMode: async (
      _,
      params: { dashboard_mode: string }
    ): Promise<{ dashboard_mode: string }> => {
      await baseApiClient('user/update', params);
      return params;
    },
    LogIn: async (
      _,
      params: LogInVariables
    ): Promise<BooleanOperation & { email: string }> => {
      await baseApiClient('user/login', params);

      return { success: true, email: params.email };
    },
    LogOut: async (): Promise<LogOut> => {
      await baseApiClient('user/logout');
      return {
        Themes: [],
        Trends: [],
        User: {
          id: null,
          firstname: '',
          lastname: '',
          email: '',
          organization: '',
          language: '',
          profile: '',
          region: '',
          since: 0
        }
      };
    }
  }
};
