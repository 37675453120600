import React from 'react';
import { useForm } from './hooks';
import type { FormProps } from './types';

export default function Form(props: FormProps) {
  const { id, children, className, ...rest } = props;
  const { onSubmit, onReset } = useForm();
  return (
    <form
      {...rest}
      onSubmit={onSubmit}
      onReset={onReset}
      id={id}
      className={className}
    >
      {children}
    </form>
  );
}
