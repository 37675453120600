import React, { useEffect, useCallback } from 'react';
import useAsyncHandler from 'src/hooks/useAsyncHandler';
import baseApiClient from 'src/apollo/client';
import Text from 'src/Text';
import type { RegionVar } from 'src/apollo/vars';

import './style.css';

type RegionSelectProps = {
  onChange: (region: RegionVar) => void;
};

const getFirstId = (data) => {
  if (Array.isArray(data) && data.length > 0) {
    return data[0].id || null;
  }
  return null;
};

const brandResolver = async () => {
  const data = await baseApiClient('brands');
  return Object.entries(data || {}).map(([id, name]) => ({
    id,
    name
  }));
};
const regionResolver = async ({ brand_id }) => {
  const data = await baseApiClient('regions', { brand_id });
  return Object.entries(data || {}).map(([name, id]) => ({
    id,
    name
  }));
};

export default function RegionSelect(props: RegionSelectProps): JSX.Element {
  const { onChange } = props;
  const [getRegions, regions] = useAsyncHandler(regionResolver);
  const [getBrands, brands] = useAsyncHandler(brandResolver);

  const initSequence = useCallback(async () => {
    const brands = await getBrands();
    const firstItem = getFirstId(brands);
    const regions = await getRegions({ brand_id: firstItem });
    onChange({
      region_id: getFirstId(regions),
      brand_id: firstItem
    });
  }, [getRegions, getBrands, onChange]);

  const onBrandChange = useCallback(
    async (e) => {
      e.preventDefault();
      const regions = await getRegions({ brand_id: e.target.value });
      onChange({
        region_id: getFirstId(regions),
        brand_id: e.target.value
      });
    },
    [onChange, getRegions]
  );

  const onRegionChange = useCallback(
    (e) => {
      e.preventDefault();
      onChange({
        region_id: e.target.value,
        brand_id: e.target.form.brand.value
      });
    },
    [onChange]
  );

  useEffect(() => {
    initSequence();
  }, [initSequence]);

  return (
    <form className='RegionSelect' onSubmit={onRegionChange}>
      <h4 className='RegionSelect-title'>
        <Text id='chooseyourbrand' fallback='Choose your brand' />
      </h4>
      <select
        name='brand'
        id='brand'
        className='RegionSelect-select'
        placeholder={brands.loading ? 'Loading...' : undefined}
        onChange={onBrandChange}
      >
        {(brands.data || []).map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
      <h4 className='RegionSelect-title'>
        <Text id='chooseyourregion' fallback='Choose your region preference' />
      </h4>
      <select
        name='region'
        id='region'
        className='RegionSelect-select'
        disabled={brands.loading || regions.loading}
        placeholder={regions.loading ? 'Loading...' : undefined}
        onChange={onRegionChange}
      >
        {(regions.data || []).map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
    </form>
  );
}
