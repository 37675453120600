import React, { Dispatch, SetStateAction } from 'react';
import cn from 'classnames';
import type { Article } from '@schema-types';

import useTranslation from 'src/Text/useTranslation';
import Text from 'src/Text';

import QueryStateMachine from 'src/Components/QueryStateMachine';
import NotFound from 'src/Components/NotFound';
import Tag from 'src/Components/Tag';
import LoadingSpinner from 'src/Components/LoadingSpinner';
import NewsArticle from 'src/Components/NewsArticle';

import ExternalSearch from '../ExternalSearch';

import './style.css';

type RelatedNewsProps = {
  title: React.ReactNode;
  selected?: string[];
  className?: string;
  loading: string | boolean | null;
  error?: Error;
  called?: boolean;
  data: Article[];
  selectedArticle?: string | null;
  onItemClick: Dispatch<SetStateAction<string>>;
  onSelectedClick?: (value: string) => void;
  onOrderChange?: React.ChangeEventHandler<HTMLSelectElement>;
};

export default function RelatedNews(props: RelatedNewsProps): JSX.Element {
  const {
    title,
    selected,
    loading,
    error,
    called,
    data,
    selectedArticle,
    className,
    onItemClick,
    onSelectedClick,
    onOrderChange
  } = props;

  const newest = useTranslation('newest', 'Newest');
  const mosttrending = useTranslation('mosttrending', 'Most trending');

  return (
    <section className={cn('RelatedNews', className)}>
      <header className='RelatedNews-header'>
        <h1 className='RelatedNews-title'>{title}</h1>
        <ExternalSearch
          selected={selected ? selected.join(',') : ''}
          disabled={!!loading}
        />
        <form className='RelatedNews-sort'>
          <label htmlFor='sort-articles'>
            <Text id='sortby' fallback='Sort by' />
          </label>
          <select
            id='sort-articles'
            disabled={!!loading}
            name='sort'
            onChange={onOrderChange}
          >
            <option value='engagement'>{mosttrending}</option>
            <option value='latest'>{newest}</option>
          </select>
        </form>
      </header>
      <ul className={cn('RelatedNews-filters', { 'state-disabled': loading })}>
        {selected
          ? selected.map((word) => (
              <li key={word}>
                <Tag onClick={onSelectedClick} value={word} />
              </li>
            ))
          : null}
      </ul>
      <div className='RelatedNews-container'>
        <QueryStateMachine
          loading={!!loading}
          error={error}
          called={called}
          length={data.length}
          loadingComponent={
            <div className='RelatedNews-loading'>
              <LoadingSpinner />
              {typeof loading === 'string' ? <p>{loading}</p> : null}
            </div>
          }
          errorComponent={
            <div className='RelatedNews-error error-message'>
              {error ? error.toString() : null}
            </div>
          }
          noDataComponent={
            <div className='RelatedNews-notfound'>
              <NotFound imageAlt='Related articles not found'>
                <Text
                  id='articlesnotfound'
                  fallback='Unfortunately we&amp;ve not found articles for this trend.'
                />
              </NotFound>
            </div>
          }
        >
          {data.map(
            ({
              hash,
              title,
              domain,
              description,
              image,
              url,
              likes,
              rate_likes
            }) => (
              <NewsArticle
                key={hash}
                id={hash}
                title={title}
                domain={domain}
                selected={selectedArticle === hash}
                likes={likes || 0}
                onClick={onItemClick}
                rate_likes={rate_likes || 0}
                url={url}
                description={description}
                image={image}
              />
            )
          )}
        </QueryStateMachine>
      </div>
    </section>
  );
}
