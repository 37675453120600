import React from 'react';
import Text from 'src/Text';
import formatDate from 'src/formatDate';

import './style.css';

function getGreetingText(hours: number): string {
  if (hours < 5) {
    return 'goodnight';
  } else if (hours < 11) {
    return 'goodmorning';
  } else if (hours < 17) {
    return 'goodday';
  } else if (hours < 21) {
    return 'goodevening';
  } else {
    return 'goodnight';
  }
}

export default function Greeting(props) {
  const { name, organization, profile } = props;
  const TODAY = new Date();
  return (
    <hgroup className='Greeting'>
      <h1 className='Greeting-title'>
        <b className='Greeting-date'>{formatDate(TODAY, 'D MMM YYYY')}</b>
        {`\u00A0`}//{`\u00A0`}
        <i className='Greeting-user'>
          <Text id={getGreetingText(TODAY.getHours())} fallback='Greetings' />,{' '}
          {name}
        </i>
      </h1>
      <h2 className='Greeting-organization'>
        {profile ? `${profile}\u00A0\//\u00A0` : null}
        {organization.split(',').join(`\u00A0\u00A0\u003E\u00A0\u00A0`)}
      </h2>
    </hgroup>
  );
}
